import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

class ModalWrapper extends Component {

    render() {
        const { open, title, subtitle, onClose, children, canClickOut = false } = this.props;

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="primary-modal-title"
                aria-describedby="primary-modal-description"
                disableBackdropClick={!canClickOut}
                disableEscapeKeyDown={!canClickOut}
            >
                <div className="modal modal--primary">
                    <div className="modal-header">
                        <Typography variant="h3">
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography className="subtitle" size="xxlg">
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                    <div className="modal-content paper">{children}</div>
                </div>
            </Modal>
        );
    }

}

export default withStyles({})(ModalWrapper);
