import jwt from 'jsonwebtoken';
import gql from 'graphql-tag';
import { joinDefined, stringIsNullOrEmpty } from './strings';
import { isNullOrUndefined } from './objects';

const STORAGE_ID = 'token';
const EXPIRY_MARGIN_SEC = 10;

let decodedToken;

export function getDecodedToken() {
    if (!decodedToken) {
        const token = getToken();
        if (token) decodedToken = jwt.decode(token.value);
    }
    return decodedToken || null;
}

export function getToken() {
    try {
        const token = JSON.parse(localStorage.getItem(STORAGE_ID));
        return token;
    } catch (e) {
        return undefined;
    }
}

export function setToken(token) {
    decodedToken = null;
    localStorage.setItem(STORAGE_ID, JSON.stringify(token));
}

export function clearToken() {
    decodedToken = null;
    localStorage.removeItem(STORAGE_ID);
}

export function getUser() {
    const token = getToken();
    if (!token) return { FirstName: '', Surname: '', ID: null };

    const names = token.name.split(' ');
    return { FirstName: names[0], Surname: names[1], ID: '' + token.userid };
}

var refreshTokenPromise = null;
export function refreshToken(client) {
    if (isNullOrUndefined(refreshTokenPromise)) {
        /*
        because we fire off heaps of requests, refreshToken can be hit multiple times
        when a page loads. We don't want this to happen... because together they cause a crash.
        this is a little hacky, but we need a bit of a mutex condition to return the "in flight"
        refresh token. so if the refreshToken request is running, we return it. otherwise we just
        kick off a new request
        */
        refreshTokenPromise = client
            .mutate({
                mutation: gql`
                    mutation RefreshToken {
                        refreshToken {
                            Token
                            Member {
                                ID
                                FirstName
                                Surname
                            }
                        }
                    }
                `
            })
            .then(({ data }) => {
                const { Token, Member } = (data && data.refreshToken) || {};
                refreshTokenPromise = null;
                if (!stringIsNullOrEmpty(Token)) {
                    // Store the new refreshed token
                    const { ID, FirstName, Surname } = Member;
                    setToken({
                        userid: ID,
                        value: Token,
                        name: joinDefined([FirstName, Surname], ' ')
                    });
                    return true;
                } else {
                    // The token refresh failed so we clear the stored token, reset
                    // apollo cache and redirect to sign in
                    clearToken();
                    // throw new Error('Token refresh failed');
                    // eslint-disable-next-line no-console
                    console.error('Token refresh failed');
                    return false;
                }
            });
    }

    return refreshTokenPromise;
}

export const isSignedIn = () => {
    const token = getDecodedToken();
    // TODO: Maybe here, or in the auth route, we should trigger a
    // token refresh if the token is expired
    return !isNullOrUndefined(token);
};

export function isTokenExpired() {
    const decodedToken = getDecodedToken();
    if (!decodedToken) return false;

    const nowSeconds = Date.now() / 1000;
    const secondsUntilExpiry = decodedToken.exp - nowSeconds;
    return secondsUntilExpiry < EXPIRY_MARGIN_SEC;
}
