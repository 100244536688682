import gql from "graphql-tag";
import {LocationFields} from "./Listing";

export default gql`
	fragment Performance on Performance {
        ID
        StartDate
        EndDate
        Prices
        Venue
        ${LocationFields}
	}
`;