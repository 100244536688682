import React, { Fragment, Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "../layout/Grid";
import Checkbox from "./Checkbox";

class CheckboxSetField extends Component {

    state = {
        fieldID: null,
        fieldOptions: null,
    };

    static getDerivedStateFromProps({ id, options }, oldState) {
        let newState = {...oldState};

        // if (id !== oldState.fieldID || (id === oldState.fieldOptions !== options)) {
        if (id !== oldState.fieldID || (oldState.fieldOptions !== options)) {
            newState.fieldOptions = options
            newState.fieldID = id
        }
        return newState;
    }

    render() {
        let { fieldOptions } = this.state;
        const { options, onChange, fieldLabel } = this.props;

        return (
            <Fragment>
                <FormLabel>{fieldLabel}</FormLabel>
                <Grid container spacing={0}>
                    {options.map((option, index) =>
                        <Grid item xs={6} sm={3} key={fieldLabel + '-' + index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={option.checked}
                                        color="primary"
                                        onChange={(e) => {
                                            fieldOptions = cloneDeep(fieldOptions);

                                            const foundIndex = fieldOptions.findIndex(o => o.id === option.id)
                                            if (foundIndex >= 0) {
                                                option.checked = e.target.checked;
                                                fieldOptions[foundIndex] = option;

                                                this.setState({ fieldOptions }, () => {
                                                    onChange(fieldOptions)
                                                })
                                            }
                                        }}
                                    />
                                }
                                label={option.label}
                            />
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    }
}

export default CheckboxSetField;