import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isSignedIn } from '../utils/sessions';
import Anonymous from './Anonymous';

const NotFound = () => {
    const message = (
        <Fragment>
            <Typography variant="h5" gutterBottom>
                Error 404: Page Not Found
            </Typography>
            <Typography gutterBottom>
                The page you are looking for has passed on. Please accept our condolences.
            </Typography>
            <Typography gutterBottom>
                <Link to="/">Return to Home</Link>
            </Typography>
        </Fragment>
    );
    return !!isSignedIn() ? message : <Anonymous>{message}</Anonymous>;
};

export default withStyles({})(NotFound);
