import React, { Component, Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import {clearToken} from "../../utils/sessions";
import { withTranslation, Trans } from 'react-i18next';
import {List, Button, Drawer, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ALLOW_EVENT_SUBMIT} from "../../_configs/env";
import {ConfirmationNumber, Dns, Home, AccountBox, ExitToApp, Menu} from "@material-ui/icons";

class Header extends Component {

    state = {
        drawerNav: false
    };

    render() {
        const { signedIn, history, location, config } = this.props;
        let cssClass = 'header';
        if (signedIn) {
            cssClass += ' header--dashboard';
        }
        return (
            <div className={cssClass}>
                <div className="app-container header__inner">
                    <div className="app-logos">
                        <a href={config.SiteLink} target='_blank' className="logo">
                            <div className="title">
                                <h2>
                                    {!!config.PortalLogoURL &&
                                    <img src={config.PortalLogoURL} className="black" alt={config.SiteTitle} />
                                    }
                                </h2>
                            </div>
                            <div className="header__title">
                                <img src={'/images/user-portal.svg'} />
                            </div>
                        </a>
                    </div>
                    <div className="header__nav">
                        {signedIn && (
                            <Fragment>
                                <div className="menu-list menu-list__primary">
                                    <Hidden mdUp={true} lgDown={false}>
                                        <Button onClick={e => {this.toggleDrawer(true)}} className="menu-list__hamburger">
                                            <Menu />
                                        </Button>
                                        <Drawer anchor='left' className={'header__drawer'} open={this.state.drawerNav} onClose={e => {this.toggleDrawer(false)}}>
                                            <ul className="menu-list__drawer">
                                                <li className="menu-list__drawer__logo">
                                                    <a href={config.SiteLink} target='_blank' className="logo">
                                                        {!!config.PortalLogoURL &&
                                                        <img src={config.PortalLogoURL} className="black" alt={config.SiteTitle} />
                                                        }
                                                    </a>
                                                </li>
                                                <li className={'menu-item ' + (location.pathname === '/' ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/')}>
                                                        <Home></Home>
                                                        <Trans i18nKey="mainMenu.home">
                                                            Home
                                                        </Trans>
                                                    </a>
                                                </li>
                                                <li className={'menu-item ' + (location.pathname.includes('/listing') ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/listings')}>
                                                        <Dns></Dns>
                                                        <Trans i18nKey="mainMenu.myListings">
                                                            Listings
                                                        </Trans>
                                                    </a>
                                                </li>
                                                {!!ALLOW_EVENT_SUBMIT && (<li className={'menu-item ' + (location.pathname.includes('/listing') ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/listings')}>
                                                        <ConfirmationNumber></ConfirmationNumber>
                                                        <Trans i18nKey="mainMenu.events">
                                                            Events
                                                        </Trans>
                                                    </a>
                                                </li>)}
                                                <li className={'menu-item ' + (location.pathname === '/account' ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/account')}>
                                                        <AccountBox></AccountBox>
                                                        <Trans i18nKey="mainMenu.account">
                                                            Account
                                                        </Trans>
                                                    </a>
                                                </li>
                                                <li className="menu-item logout">
                                                    <a onClick={e => this.onClickLogout()}>
                                                        <ExitToApp></ExitToApp>
                                                        <Trans i18nKey="mainMenu.logout">
                                                            Log out
                                                        </Trans>
                                                    </a>
                                                </li>
                                            </ul>
                                        </Drawer>
                                    </Hidden>
                                    <Hidden only={['xs', 'sm']} >
                                        <ul className="header__nav__list">
                                            <li className={'menu-item ' + (location.pathname === '/' ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/')}>
                                                    <Trans i18nKey="mainMenu.home">
                                                        Home
                                                    </Trans>
                                                </a>
                                            </li>
                                            <li className={'menu-item ' + (location.pathname.includes('/listing') ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/listings')}>
                                                    <Trans i18nKey="mainMenu.myListings">
                                                        Listings
                                                    </Trans>
                                                </a>
                                            </li>
                                            {!!ALLOW_EVENT_SUBMIT && (
                                                <li className={'menu-item ' + (location.pathname === '/events' ? 'active' : '')}>
                                                    <a onClick={e => history.push('/events')}>
                                                        <Trans i18nKey="mainMenu.events">
                                                            Events
                                                        </Trans>
                                                    </a>
                                                </li>
                                            )}
                                            <li className={'menu-item ' + (location.pathname === '/account' ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/account')}>
                                                    <Trans i18nKey="mainMenu.account">
                                                        Account
                                                    </Trans>
                                                </a>
                                            </li>
                                            <li className="menu-item logout">
                                                <a onClick={e => this.onClickLogout()}>
                                                    <Trans i18nKey="mainMenu.logout">
                                                        Log out
                                                    </Trans>
                                                    <ExitToApp />
                                                </a>
                                            </li>
                                        </ul>
                                    </Hidden>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    loadPage(url) {
        const { history } = this.props;
        history.push(url);
        this.toggleDrawer(false);
    }

    onClickLogout = () => {
        const { history } = this.props;
        clearToken();
        history.push('/');
    };

    toggleDrawer(open) {
        this.setState({ drawerNav: open });
    }
}

export default withTranslation()(Header);
