import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { SnackbarMessageProvider } from '../context/SnackbarMessage';
import { getClient } from '../utils/apollo';
import history from '../utils/history';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
});

const client = getClient();

export default ({ children }) => (
    <SnackbarMessageProvider>
        <ApolloProvider client={client}>
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </Router>
        </ApolloProvider>
    </SnackbarMessageProvider>
);
