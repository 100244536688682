import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { joinDefined } from '../../utils/strings';
import {getUser} from "../../utils/sessions";

const ListingAutoComplete = ({ category, className, name, clearOnSelect = true, includeEvents = false, ...props }) => {
    return (
        <GqlAutocomplete
            className={className}
            readAllQuery={readListings}
            placeholder="Listing..."
            labelFieldFunc={e => e.Title}
            multiple={true}
            name={name}
            clearOnSelect={clearOnSelect}
            extraVariables={{ memberID: 0, includeEvents: includeEvents }}
            {...props}
        />
    );
};

const readListings = gql`
    query ReadListings($contains: String, $includeEvents: Boolean, $memberID: ID, $limit: Int) {
        readListings(contains: $contains, includeEvents: $includeEvents, memberID: $memberID, limit: $limit, offset: 0) {
            edges {
                node {
                    ID
                    Title
                }
            }
        }
    }
`;

export default ListingAutoComplete;
