import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import { Listing } from "../../../fragments/Listing";
import Accordion from "../../../components/accordion/Accordion";
import { Map, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ListingTab from "./ListingTab";
import Checkbox from "../../../components/form/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MAP_DEFAULT_CENTER} from "../../../_configs/env";
import FormHelperText from "@material-ui/core/FormHelperText";

L.Icon.Default.imagePath='/images/';

export class ContactDetails extends ListingTab {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: MAP_DEFAULT_CENTER,
            zoom: 10,
            currentPos: null,
        };
        this.handleMapClick = this.handleMapClick.bind(this);
    }

    componentDidMount() {
        const { form } = this.props;
        const location = form.getField('Location');

        if (location) {
            this.setState({ currentPos: this.parseLocation(location) });
        }
    }

    render() {
        const { form } = this.props;
        const objectType = form.getField('ClassNameShort');
        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        {this.renderLocationDetails()}

                        {(objectType === 'Accommodation') && (
                            <Accordion
                                title="Proximity"
                                titleI18nKey="myListings.editView.tabProximity"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderProximityDetails()}
                            </Accordion>
                        )}

                        <Accordion
                            title="Map"
                            titleI18nKey="myListings.editView.tabMap"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderLocationMap()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderLocationDetails() {
        const { form } = this.props;
        const streetAddress = <Trans i18nKey="myListings.editView.address">Street Address</Trans>;
        const city = <Trans i18nKey="myListings.editView.city">City</Trans>;
        const suburb = <Trans i18nKey="myListings.editView.suburb">Suburb</Trans>;
        const state = <Trans i18nKey="myListings.editView.state">State</Trans>;
        const postCode = <Trans i18nKey="myListings.editView.postCode">Post Code</Trans>;
        const country = <Trans i18nKey="myListings.editView.country">Country</Trans>;
        const physicalAddressAsPostal = <Trans i18nKey="myListings.editView.physicalAddressAsPostal">Use physical address as postal address</Trans>;

        return (
            <Fragment>
                <Accordion
                    title="Physical Address"
                    titleI18nKey="myListings.editView.tabAddress1"
                    className='form-panel'
                    defaultExpanded
                >
                    <Grid container spacing={0}>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="StreetAddress"
                                variant="outlined"
                                label={streetAddress}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="City"
                                variant="outlined"
                                label={city}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="Suburb"
                                variant="outlined"
                                label={suburb}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="State"
                                variant="outlined"
                                label={state}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="PostCode"
                                variant="outlined"
                                label={postCode}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="Country"
                                variant="outlined"
                                label={country}
                                fullWidth
                            />
                        </Grid>
                        <Grid item className="field-holder">
                            <FormControlLabel
                                control={<Checkbox form={form} name="PhysicalAddressAsPostal" color="primary" />}
                                label={physicalAddressAsPostal}
                            />
                        </Grid>
                    </Grid>
                </Accordion>

                {!form.getField('PhysicalAddressAsPostal') && (
                    <Accordion
                        title="Postal Address"
                        titleI18nKey="myListings.editView.tabAddress2"
                        className='form-panel'
                        defaultExpanded
                    >
                        <Grid container spacing={0}>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalStreetAddress"
                                    variant="outlined"
                                    label={streetAddress}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalCity"
                                    variant="outlined"
                                    label={city}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalSuburb"
                                    variant="outlined"
                                    label={suburb}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalState"
                                    variant="outlined"
                                    label={state}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalPostCode"
                                    variant="outlined"
                                    label={postCode}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="PostalCountry"
                                    variant="outlined"
                                    label={country}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                )}
            </Fragment>
        )
    }

    renderProximityDetails() {
        const { form } = this.props;
        const proximityToTown = <Trans i18nKey="myListings.editView.proximityToTown">Proximity To Town</Trans>;
        const proximityToAirport = <Trans i18nKey="myListings.editView.proximityToAirport">Proximity To Airport</Trans>;
        const proximityToRailway = <Trans i18nKey="myListings.editView.proximityToRailway">Proximity To Railway</Trans>;
        const proximityToBus = <Trans i18nKey="myListings.editView.proximityToBus">Proximity To Bus</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToTown"
                        type="number"
                        variant="outlined"
                        label={proximityToTown}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToAirport"
                        type="number"
                        variant="outlined"
                        label={proximityToAirport}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToRailway"
                        type="number"
                        variant="outlined"
                        label={proximityToRailway}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </FormHelperText>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToBus"
                        type="number"
                        variant="outlined"
                        label={proximityToBus}
                        fullWidth
                    />
                    <FormHelperText>
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </FormHelperText>
                </Grid>
            </Grid>
        )
    }

    renderLocationMap() {
        const { form } = this.props;
        const { currentPos, defaultCenter } = this.state;

        return (
            <Map
                scrollWheelZoom={false}
                center={currentPos ? currentPos : defaultCenter}
                zoom={this.state.zoom}
                onClick={this.handleMapClick}
            >
                <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'/>

                { this.state.currentPos && <Marker position={this.state.currentPos} draggable={true} /> }
            </Map>
        );
    }

    handleMapClick(e) {
        const { form } = this.props;
        this.setState({ currentPos: e.latlng });

        form.setField({ Location: e.latlng });
    }

    parseLocation = (wkt) => {
        const parts = wkt.match(/\b(point|linestring|polygon|multipolygon)\s*\((.*)\)/i);

        const coordinates = parts[2]
            .replace(/([\d\.-]+)\s+([\d\.-]+)/g, function(p,c1,c2) {
                return '{"lat": ' + c2 + ', "lng": ' + c1 + '}';
            }).replace(/([\(\)])/g, function(p,c) {
                return c === '(' ? '' : '';
            });

        return JSON.parse(coordinates);
    };
}

export default {
    id: 'contact',
    tabURL: '/listing/edit/:id/location',
    label: 'Location',
    component: withTranslation()(ContactDetails),
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle location save
        const location = state.Location;
        if (location && typeof location === 'object' && location.lat && location.lng) {
            saveData.Location = `POINT(${location.lng} ${location.lat})`;
        }

    }
};