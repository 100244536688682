import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import TextField from '../../components/form/TextField';
import Grid from '../../components/layout/Grid';
import { getAuthClient } from '../../utils/apollo';

class Register extends Component {
    state = {
        email: '',
        firsName: '',
        surname: '',
        password: '',
        phoneNumber: '',
        confirmPassword: '',
        tAndC: false,
        registerError: null,
        verifyError: null,
        verificationPending: false,
        code: null,
        resendEmailEditable: false,
        newEmail: '',
        verified: false,
    };

    static getDerivedStateFromProps({ match, location }, oldState) {
        if (match.params.type && match.params.type === 'verify') {
            const state = {
                verificationPending: true
            };
            const queries = queryString.parse(location.search);
            if (queries) {
                if (queries.email) {
                    state.email = queries.email;
                }
                if (queries.code) {
                    state.code = queries.code;
                }
            }
            return state;
        }
        return null;
    }

    componentDidMount() {
        if (localStorage.getItem('verification_pending')) {
            this.setState({ verificationPending: true });
        }
        if (localStorage.getItem('verifying_email')) {
            this.setState({ email: localStorage.getItem('verifying_email') });
        }

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const { password } = this.state;
            return value === password
        });
    }

    render() {
        const { config } = this.props;

        return (
            <Fragment>
                <div className="sign-in">
                    <div className="sign-in__header">
                        <img src={config.PortalLogoURL} className="black" alt={config.SiteTitle} />
                    </div>
                    <Card className='welcome-card'>
                        {this.renderContent()}
                    </Card>
                    <div className="sign-in__footer">
                        <p>Solution by <a href="https://konduitapp.com" target="_blank">Konduit</a></p>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderContent() {
        const { verificationPending, verified } = this.state;

        if (verified) {
            return (
                <Grid container>
                    <Grid item>
                        <Typography variant="h3" gutterBottom>
                            All set, thank you.
                        </Typography>
                        <Typography component="p" variant="body1" gutterBottom>
                            Please click <strong><a href="/login">here</a></strong> to login.
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else if (verificationPending) {
            return (
                <Fragment>
                    <Mutation
                        client={getAuthClient()}
                        mutation={verifyMemberRequestMutation}
                        onCompleted={this.onVerificationCompleted}
                        onError={e => {
                            this.setState({verifyError: e.message.replace('GraphQL error: ', '')})
                        }}
                    >
                        {this.renderVerificationForm}
                    </Mutation>

                    <Mutation
                            client={getAuthClient()}
                            mutation={resendVerificationMutation}
                            onCompleted={this.onResendCompleted}
                            onError={e => {
                                this.setState({error: e.message.replace('GraphQL error: ', '')})
                            }}
                        >
                            {this.renderResendVerifyForm}
                    </Mutation>
                </Fragment>
            );
        } else {
            return (
                <Mutation
                    client={getAuthClient()}
                    mutation={createMemberRequestMutation}
                    onCompleted={this.onRegisterCompleted}
                    onError={e => {
                        this.setState({registerError: e.message.replace('GraphQL error: ', '')})
                    }}
                >
                    {this.renderRegisterForm}
                </Mutation>
            );
        }
    }

    renderVerificationForm = (mutate, results = {}) => {
        const { data, loading } = results;

        const { code, email, verifyError } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();

            this.setState({verifyError: null});
            if (loading) return;
            mutate({
                variables: { code }
            });
        };

        return (
            <form className="sign-in login-popup" onSubmit={onSubmit}>
                <Typography variant="h3" gutterBottom>
                    Please verify your email
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A verification code has been sent to {email} Please use that code to proceed
                </Typography>
                <div className="divider-line" />

                {verifyError && (
                    <Typography component="p" variant="body1" className="form-message form-message--bad">
                        {verifyError}
                    </Typography>
                )}

                <div className='field-holder'>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('code')}
                        label="code"
                        value={code}
                        variant="outlined"
                    />
                </div>
                <div className='action-buttons'>
                    <Button
                        type="submit"
                        variant="contained"
                        className="button-base primary"
                    >
                        Verify
                    </Button>
                </div>
            </form>
        );
    };

    renderResendVerifyForm = (mutate, results = {}) => {
        const { data, loading } = results;

        const { code, email, resendEmailEditable, newEmail } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();

            this.setState({error: null});
            if (loading) return;
            mutate({
                variables: { email, newEmail }
            });
        };

        return (
            <form className="sign-in login-popup" onSubmit={onSubmit}>
                <Typography variant="h3" gutterBottom>
                    Didnt receive yet?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    You entered the email below, check if thats correct.
                </Typography>
                <div className='field-holder'>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('newEmail')}
                        label="Email"
                        value={newEmail ? newEmail : email}
                        disabled={!resendEmailEditable}
                        variant="outlined"
                    />

                    <p className="remove-bottom">Not your email address?</p>
                    <p><a href="#" onClick={(e) => {
                        e.preventDefault();
                        this.setState({ resendEmailEditable: true });
                        return false;
                    }}>
                        <strong>Click here to edit.</strong>
                    </a></p>

                </div>
                <div className='action-buttons'>
                    <Button
                        type="submit"
                        variant="contained"
                        className="button-base primary"
                    >
                        Re-send
                    </Button>
                </div>
            </form>
        );
    };

    renderRegisterForm = (mutate, results = {}) => {
        const { data, loading } = results;

        const { email, firstName, surname, phoneNumber, password, confirmPassword, tAndC, registerError } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();

            this.setState({registerError: null});
            if (loading) return;
            mutate({
                variables: { email, firstName, surname, phoneNumber, password }
            });
        };

        return (
            <ValidatorForm
                ref="form"
                onSubmit={onSubmit}
                onError={errors => console.log(errors)}
                className="sign-in login-popup"
            >
                <h3>
                    Sign up to Tourism Business Database
                </h3>
                <p>
                    To sign up for free access to the Tourism Business Database, create a user profile to manage your
                    business and listing(s) information. These details will only be used to contact you about managing
                    your business and listing information.
                </p>

                {registerError && (
                    <p className="form-message form-message--bad">
                        {registerError}
                    </p>
                )}

                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('firstName')}
                        label="First Name"
                        value={firstName}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('surname')}
                        label="Last Name"
                        value={surname}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('email')}
                        label="Email"
                        value={email}
                        inputMode="email"
                        validators={['required', 'isEmail']}
                        errorMessages={['this field is required', 'email is not valid']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('phoneNumber')}
                        label="Phone Number"
                        value={phoneNumber}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('password')}
                        label="Password"
                        value={password}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('confirmPassword')}
                        label="Confirm Password"
                        value={confirmPassword}
                        type="password"
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={['password mismatch', 'this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className="remember-links field-holder">
                    <FormControlLabel
                        control={<Checkbox checked={tAndC} onChange={this.onToggleTAndC} color="primary" />}
                        label="I have read and accept the Terms and conditions"
                    />
                </div>
                <div className='action-buttons'>
                    <Button
                        type="submit"
                        variant="contained"
                        className="button-base primary"
                        disabled={!tAndC}
                    >
                        Create new account
                    </Button>
                    <Typography component="p" variant="body1" className="error-text">
                        <a href="/login">Already have an account?</a>
                    </Typography>
                </div>
            </ValidatorForm>
        );
    };

    onChangeText = name => e => {
        this.setState({ [name]: e.target.value });
    };

    onToggleTAndC = e => {
        this.setState({ tAndC: e.target.checked });
    };

    onVerificationCompleted = data => {
        const memberRequest = (data && data.verifyMemberRequest) || {};
        if (!memberRequest || !memberRequest.Verified) return;

        localStorage.removeItem('verification_pending');
        localStorage.removeItem('verifying_email');
        this.setState({
            verificationPending: false,
            verified: true,
        });
    };

    onRegisterCompleted = data => {
        const memberRequest = (data && data.createMemberRequest) || {};
        if (!memberRequest) return;

        localStorage.setItem('verification_pending', 1);
        localStorage.setItem('verifying_email', memberRequest.Email);
        this.setState({ verificationPending: true });
    };

    onResendCompleted = data => {
        const memberRequest = (data && data.resendVerification) || {};
        if (!memberRequest) return;

        this.setState({ email: memberRequest.Email})
    };z
}

const createMemberRequestMutation = gql`
    mutation SignUp($firstName: String!, $surname: String, $email: String!, $phoneNumber: String!, $password: String!) {
        createMemberRequest(FirstName: $firstName, Surname: $surname, Email: $email, PhoneNumber: $phoneNumber, Password: $password) {
            ID
            FirstName
            Surname
            Email
        }
    }
`;

export const verifyMemberRequestMutation = gql`
    mutation VerifyMemberRequest($code: String!) {
        verifyMemberRequest(VerificationCode:$code) {
            ID
            Verified
            Email
            FirstName
        }
    }
`;

export const resendVerificationMutation = gql`
    mutation ResendVerificationCode($email: String!, $newEmail: String) {
        resendVerification(Email: $email, NewEmail: $newEmail) {
            ID
            Email
        }
    }
`;

export default Register;
