import gql from "graphql-tag";

export default gql`
	fragment Member on Member {
        ID
        Email
        FirstName
        Surname
        PhoneNumber
	}
`;