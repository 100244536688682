//Validation 1.0.1
import React from 'react';
import {isNullOrUndefined} from 'util';
import {getProperty} from './objects';
import {stringIsNullOrEmpty} from './strings';
import TickCircleIcon from '../components/icon/TickCircleIcon';
import ExclamationTriangleIcon from '../components/icon/ExclamationTriangleIcon';
import ExclamationCircleIcon from '../components/icon/ExclamationCircleIcon';

/*
To apply a validation to a dataform, set the following properties on the Tab object:
export const MyTab = {
    label: 'Deceased',
    component: Deceased,
    fragment: DeceasedFragment,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {},

    validation: {
        required: ["FirstName", "Surname"],
        optional: ["MiddleName"],
        suggested: ["KnownAs"],

        //newValue = the value that is about to be set
        //persistedValue = the old value that is saved on to the database OR the new value to be set
        //hasValue = if the value is changing (a new value has been specified)
        //getState = gets an old or new value off the existing database
        onValidate: {
            "PlaceOfViewingItems[0].Date": (newValue, persistedValue, hasValue, getState) => {

                if(!hasValue || isNullOrUndefined(newValue)){
                    return validationHelper.ok();
                }

                const placeOfViewing = getState('PlaceOfViewingItems[0].Location');
                console.log('place of viewin', placeOfViewing, isRelatedObjectUndefined(placeOfViewing));

                return isRelatedObjectUndefined(placeOfViewing)
                    ? validationHelper.required("Please select a Place of Viewing", ["PlaceOfViewingItems[0].Location"])
                    : validationHelper.ok();
            }
        }
    }
};
*/

export const messages = {
    invalidEmail: 'Please enter a valid email address',
    required: 'This field is required',
    suggested: 'This field should be filled out',
    optional: 'This field is optional',
    missing: text => `Add at least one ${text}`
};

export const requirement = {
    optional: 0,
    suggested: 1,
    required: 2
};

export const validationHelper = {
    optional: (message = messages.optional, dependentFields = []) => ({
        message,
        requirement: requirement.optional,
        valid: true,
        shouldUpdate: false,
        dependentFields
    }),
    suggested: (message = messages.suggested, dependentFields = []) => ({
        message,
        requirement: requirement.suggested,
        valid: true,
        shouldUpdate: false,
        dependentFields
    }),
    required: (message = messages.required, dependentFields = []) => ({
        message,
        requirement: requirement.required,
        valid: false,
        shouldUpdate: false,
        dependentFields
    }),
    ok: () => ({
        message: null,
        requirement: null,
        valid: true,
        shouldUpdate: false,
        dependentFields: []
    })
};

/**
 * joins multiple results together
 */
export const joinValidationResults = validationResults => {
    if (isNullOrUndefined(validationResults)) {
        throw new Error('Validation results null. cannot be joined');
    }

    if (validationResults.length === 0) return validationHelper.ok();

    let validationResult = validationHelper.ok();

    validationResults.forEach(result => {
        if (!stringIsNullOrEmpty(result.message)) {
            validationResult.message = stringIsNullOrEmpty(
                validationResult.message
            )
                ? result.message
                : validationResult.message + ' ' + result.message;
        }

        if (
            isNullOrUndefined(validationResult.requirement) ||
            result.requirement > validationResult.requirement
        )
            validationResult.requirement = result.requirement;

        if (!result.valid && validationResult.valid)
            validationResult.valid = false;

        if (result.shouldUpdate && !validationResult.shouldUpdate)
            validationResult.shouldUpdate = true;

        if (result.dependentFields.length > 0)
            validationResult.dependentFields = validationResult.dependentFields.concat(
                result.dependentFields
            );
    });

    return validationResult;
};

/**
 * entry point to validate the dataform
 */
export const validationUtility = {
    createState: () => {
        return createValidationState();
    },

    validate: (dataForm, validation) => {
        if (isNullOrUndefined(validation)) return;

        //reset the state
        dataForm.state.validation = createValidationState();
        let invalidCount = 0;
        let validationResults = [];

        Object.keys(validation).forEach(property => {
            const validationSummary = validate(
                dataForm,
                validation,
                property
            );
            invalidCount += validationSummary.invalid;

            hideOrShowBadge(
                validationSummary.invalid,
                `[tab-error='${property}']`
            );
            hideOrShowBadge(
                validationSummary.suggested,
                `[tab-suggested='${property}']`
            );
            validationResults.push(validationSummary);
        });

        return invalidCount === 0;
    },

    validateTabs: (dataForm, tabs) => {
        //reset the state
        dataForm.state.validation = createValidationState();
        let invalidCount = 0;
        let validationResults = [];
        for (const property in tabs) {
            const tab = tabs[property];
            const loaded = dataForm.loaded[tab.id] === true;
            if (isNullOrUndefined(tab.validation) || !loaded) continue;

            const validationSummary = validate(
                dataForm,
                tab.validation,
                tab.label
            );
            invalidCount += validationSummary.invalid;

            hideOrShowBadge(
                validationSummary.invalid,
                `[tab-error='${tab.id}']`
            );
            hideOrShowBadge(
                validationSummary.suggested,
                `[tab-suggested='${tab.id}']`
            );

            validationResults.push(validationSummary);
        }

        return invalidCount === 0;
    },

    getValidationResult: (fieldName, validation, revalidate = false) => {
        if (typeof fieldName === 'object') {
            return getComponentFieldsValidationResult(
                fieldName,
                validation,
                revalidate
            );
        } else if (typeof fieldName === 'string') {
            return getFieldValidationResult(fieldName, validation, revalidate);
        }

        throw new Error('Unknown validation type ' + fieldName);
    }
};

/**
 * gets an individual field validation result
 * @param {*} fieldName
 * @param {*} validation
 * @param {*} revalidate
 */
const getFieldValidationResult = (
    fieldName,
    validation
    //revalidate = false
) => {
    let validationResult = null;
    const dependentField = validation.__dependentLookup[fieldName];

    if (dependentField) {
        //select the dependent field for validation
        validationResult = validation[dependentField];
    } else if (
        validation[fieldName] &&
        (isNullOrUndefined(validation[fieldName].dependentFields) ||
            validation[fieldName].dependentFields.length === 0)
    ) {
        //select the original validation, as long as its not a dependent validation
        validationResult = validation[fieldName];
    }

    if (!validationResult) return validationHelper.ok();

    /*todo: re-enable this later
    don't revalidate right now. the tab badget doesn't update at the same time. disconnected experience
    if(!revalidate || !validationResult.revalidate)
        return validationResult;

    return validationResult.revalidate();
    */
    return validationResult;
};

/**
 * gets component field validation results
 */
const getComponentFieldsValidationResult = (
    componentFields,
    validation,
    revalidate = false
) => {
    const validationResults = Object.keys(componentFields).map(key =>
        getFieldValidationResult(componentFields[key], validation, revalidate)
    );
    return joinValidationResults(validationResults);
};

/**
 * hides or shows the badge element
 */
const hideOrShowBadge = (issues, elementKey) => {
    const element = document.querySelector(elementKey);
    if (isNullOrUndefined(element)) return;

    if (issues > 0) {
        element.style.display = 'flex';
        element.innerHTML = issues;
    } else {
        element.style.display = 'none';
        element.innerHTML = '';
    }
};

const createValidationState = () => {
    return { __dependentLookup: {} };
};

/**
 * combines an old and new validation result
 */
const revalidate = (
    oldValidationResult,
    newValidationResult,
    dataForm,
    fieldName
) => {
    newValidationResult.shouldUpdate =
        oldValidationResult.valid !== newValidationResult.valid;
    if (isNullOrUndefined(fieldName)) {
        throw new Error('fieldName is null');
    }
    dataForm.state.validation[fieldName] = {
        ...oldValidationResult,
        ...newValidationResult
    };
    return newValidationResult;
};

const validate = (dataForm, validation, componentName) => {
    //validate using simple validation
    let validationResults = verifyFields(
        validation.required,
        requirement.required,
        dataForm
    )
        .concat(
            verifyFields(validation.suggested, requirement.suggested, dataForm)
        )
        .concat(
            verifyFields(validation.optional, requirement.optional, dataForm)
        );

    //validate using complex validation
    if (validation.onValidate) {
        const getOtherField = otherFieldName =>
            getOtherDataFormField(dataForm, otherFieldName);

        Object.keys(validation.onValidate).forEach(fieldName => {
            const createResult = () => {
                const onValidateField = validation.onValidate[fieldName];
                const newValue = getProperty(dataForm.getTabState(), fieldName);
                const originalValue = getProperty(dataForm.getOriginalState(), fieldName);
                const hasValue = fieldHasValue(newValue, originalValue);
                return onValidateField(
                    newValue,
                    newValue !== undefined ? newValue : originalValue,
                    hasValue,
                    getOtherField
                );
            };

            const validationResult = createResult();
            if (isNullOrUndefined(validationResult)) {
                throw new Error(
                    componentName +
                    '_' +
                    fieldName +
                    ' custom validation returned nothing. It must return something'
                );
            }

            validationResults.push({
                fieldName,
                validationResult,
                revalidate: () =>
                    revalidate(
                        validationResult,
                        createResult(),
                        dataForm,
                        fieldName
                    )
            });
        });
    }

    //set states
    validationResults.forEach(({ validationResult, revalidate, fieldName }) => {
        if (isNullOrUndefined(fieldName)) {
            throw new Error('fieldName is null');
        }

        if (
            validationResult.dependentFields &&
            validationResult.dependentFields.length > 0
        ) {
            //set the dependent field's validation
            validationResult.dependentFields.forEach(dependentField => {
                dataForm.state.validation.__dependentLookup[
                    dependentField
                    ] = fieldName;
            });
        }

        dataForm.state.validation[fieldName] = {
            ...validationResult,
            revalidate
        };
    });

    //calculate the summary
    const summary = {
        invalid: 0,
        suggested: 0,
        optional: 0
    };
    for (let x = 0; x < validationResults.length; x++) {
        if (!validationResults[x].validationResult.valid) {
            summary.invalid++;
        } else if (
            validationResults[x].validationResult.requirement ===
            requirement.suggested
        ) {
            summary.suggested++;
        } else if (
            validationResults[x].validationResult.requirement ===
            requirement.optional
        ) {
            summary.optional++;
        }
    }

    //console.log('!VALIDATION!:' + componentName + ':', validationResults);
    return summary;
};

/**
 * gets the new value to set the field OR existing field value
 * @param {*} dataForm
 * @param {*} fieldName
 */
const getOtherDataFormField = (dataForm, fieldName) => {
    const newValue = getProperty(dataForm.getTabState(), fieldName);
    const originalValue = getProperty(dataForm.getOriginalState(), fieldName);
    return newValue !== undefined ? newValue : originalValue;
};

/**
 * iterates over a collection of optional, required or recommend fields and tests if they are set or unset
 * @param {*} fieldNames
 * @param {*} requirement
 * @param {*} dataForm
 */
const verifyFields = (fieldNames, fieldRequirement, dataForm) => {
    if (!fieldNames) return [];

    let unsetFunction = null;
    if (fieldRequirement === requirement.optional)
        unsetFunction = () => validationHelper.optional(messages.optional);
    else if (fieldRequirement === requirement.suggested)
        unsetFunction = () => validationHelper.suggested(messages.suggested);
    else if (fieldRequirement === requirement.required)
        unsetFunction = () => validationHelper.required(messages.required);
    else {
        throw new Error('unknown requirement level ' + fieldRequirement);
    }


    return fieldNames
        .filter(fieldName => isFieldLoaded(dataForm, fieldName))
        .map(fieldName => {
            const createResult = () =>
                isIncomingDataFormValueNotEmpty(dataForm, fieldName)
                    ? validationHelper.ok()
                    : unsetFunction();

            const validationResult = createResult();

            return {
                fieldName,
                validationResult,
                revalidate: () =>
                    revalidate(
                        validationResult,
                        createResult(),
                        dataForm,
                        fieldName
                    )
            };
        });
};

/**
 * checks a dataform's value against its new and old value to see if its set or about to be set to null
 */
const isIncomingDataFormValueNotEmpty = (dataForm, fieldName) => {
    const newValue = getProperty(dataForm.getTabState(), fieldName);
    const originalValue = getProperty(dataForm.getOriginalState(), fieldName);
    return isIncomingValueNotEmpty(newValue, originalValue);
};

/**
 * checksnetworkInterfaces a new and old value to see if the new value will be present when applied
 * @param {*} newValue
 * @param {*} originalValue
 */
const isIncomingValueNotEmpty = (newValue, originalValue) => {
    //new value specified
    if (!(newValue === null || newValue === undefined || newValue === '')) return true;

    //the incoming value is not present, but the old value is present
    if (newValue === undefined && !(originalValue === null || originalValue === undefined || originalValue === ''))
        return true;

    //new value is null and old value is either present or unset
    return false;
};

/**
 * filter values that will be set
 */
const isFieldLoaded = (dataForm, fieldName) => {
    const originalValue = getProperty(dataForm.getOriginalState(), fieldName, null, true);

    return originalValue !== undefined;
};

const fieldHasValue = (newValue, originalValue) => {
    //new value specified
    if (newValue !== undefined && !(newValue === null || newValue === '')) return true;
    if (newValue === null || newValue === '') return false;

    //new value is undefined, check if the original value is present
    return !(originalValue === undefined || originalValue === null || originalValue === '');
};

export const hasErrorsInFields = (fields, form) => {
    return fields.some((field) => {
        const decoration = getValidationDecorations({ form: form, name: field });
        return decoration.inError;
    });
};

/**
 * gets the validation decorations
 */
export const getValidationDecorations = (props, componentFields, prefix) => {
    const { form, name, error, classes } = props;

    let decorations = {
        inError: false,
        errorMsg: '',
        cssClass: '',
        focusedClass: classes && classes.focused,
        validationLabel: classes && classes.validationLabel,
        labelShrinkClass: classes && classes.labelShrink,
        validationIcon: null
    };

    let validationResult;
    if (form) {
        if (!form.getValidation) {
            throw new Error(
                name + ' form does not contain validation function!'
            );
        }

        if (!isNullOrUndefined(componentFields)) {
            validationResult = form.getValidation(componentFields);
        } else if (!isNullOrUndefined(name)) {
            validationResult = form.getValidation(name);
        } else {
            throw new Error('no idea what validation to use on ' + name);
        }
    }

    if (
        isNullOrUndefined(form) &&
        isNullOrUndefined(name) &&
        !isNullOrUndefined(props.validationResult)
    ) {
        validationResult = props.validationResult;
    }

    if (validationResult) {
        if (
            validationResult.requirement === requirement.required ||
            !validationResult.valid
        ) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}error`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <ExclamationCircleIcon />;
        } else if (validationResult.requirement === requirement.suggested) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}suggested`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <ExclamationTriangleIcon />;
        } else if (validationResult.requirement === requirement.optional) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}optional`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <TickCircleIcon />;
        }
    }

    if (!stringIsNullOrEmpty(error)) {
        decorations.inError = true;
        decorations.errorMsg = error;
        decorations.cssClass = 'validation--error';
        decorations.validationIcon = <ExclamationCircleIcon />;
    }

    return decorations;
};

export const enableValidation = true;

export const isEmailValid = email => {
    if (stringIsNullOrEmpty(email)) return false;

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(email);
};