import gql from "graphql-tag";
import {ListingFields, TripAdvisorFields, QualmarkFields, BookitFields} from "./Listing";

export const Accommodation = (includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields) => {
	return gql`
		fragment Accommodation on Accommodation {
			${ListingFields(includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields)}
			
			CheckIn
			CheckOut
			NumOfRooms
			NumOfFunctionRooms
			TheatreCapacity
			BanquetCapacity
			CocktailCapacity
			BoardRoomCapacity
		}
	`;
}
