import gql from "graphql-tag";

export default gql`
	fragment Category on Category {
        ID
        Title
        ClassType
        Parent {
            ID
        }
        Children {
            ID
            Title
            ClassType
            Parent {
                ID
            }
            Children {
                ID
                Title
                ClassType
                Parent {
                    ID
                }
                Children {
                    ID
                    Title
                    ClassType
                    Parent {
                        ID
                    }
                    Children {
                        ID
                        Title
                        ClassType
                        Parent {
                            ID
                        }
                        Children {
                            ID
                            Title
                            ClassType
                            Parent {
                                ID
                            }
                        }
                    }
                }
            }
        }
	}
`;