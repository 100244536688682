import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {isNullOrUndefined} from 'util';
import {joinDefined} from '../../utils/strings';

export default withStyles({})(
	({
		pc,
		className,
		children,
		bucket,
		spacing,
		xs,
		sm,
		md,
		lg,
		xl,
		item,
		container,
		key
	}) => {

		if (container) {
			if (isNullOrUndefined(spacing))
				spacing = 10;
			
			if (isNullOrUndefined(pc))
				pc = 1;
		}
		
		let pcProps;
		if (typeof pc !== 'undefined' || bucket === true) {
			pcProps = {
				item: true,
				xs: 12,
				sm: Math.floor(12 * (pc || 1))
			};
		}

		if (item && isNullOrUndefined(xs))
			xs = 12;

		if (bucket) {
			pcProps.container = true;
			pcProps.spacing = spacing || 16;
		}

		const classNames = joinDefined([className], ' ');

		if (bucket) { 
			return (

					<Grid
						style={{margin:0}}
						className={`grid grid--bucket ${classNames}`}
						spacing={spacing}
						xs={xs}
						sm={sm}
						md={md}
						lg={lg}
						xl={xl}
						item={item}
						container={container}
						key={key}
						{...pcProps}
					>
						{children}
					</Grid>
				);
		}

		return (
			<Grid
				className={`grid ${classNames}`}
				style={{margin:0}}
				spacing={spacing}
				xs={xs}
				sm={sm}
				md={md}
				lg={lg}
				xl={xl}
				item={item}
				container={container}
				key={key}
				{...pcProps}
			>
				{children}
			</Grid>
		);
	}
);
