import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button';
import Grid from "../../../components/layout/Grid";
import UploadField, { getFileName } from '../../../components/form/UploadField';
import { Listing } from "../../../fragments/Listing";
import ListingTab from "./ListingTab";
import TextField from "../../../components/form/TextField";
import {FormHelperText} from "@material-ui/core";

class Media extends ListingTab {

    render() {
        const { config, form } = this.props;

        const logo = form.getField('Logo');
        const mainImage = form.getField('MainImage');
        const galleryImages = form.getField('GalleryImages');
        return (
            <Fragment>
                <Grid container spacing={4}>
                    <Grid item className="panels">
                        <Grid container spacing={0}>
                            {!!config.EnableLogoField && (
                                <Grid item className="field-holder">
                                    <h4><Trans i18nKey="myListings.editView.logo">Logo</Trans></h4>
                                    <UploadField
                                        folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                                        name="Logo"
                                        form={form}
                                    />
                                    <FormHelperText>
                                        <Trans i18nKey="myListings.editView.logoHelp">
                                            Upload in JPG, PNG format. Maximum file size allowed is 2MBs
                                        </Trans>
                                    </FormHelperText>

                                    {!!logo && !!logo.ID && this.renderImage(logo, this.onLogoRemove.bind(this))}
                                </Grid>
                            )}

                            <Grid item className="field-holder">
                                <h4><Trans i18nKey="myListings.editView.mainImage">Main Image</Trans></h4>
                                <UploadField
                                    folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                                    name="MainImage"
                                    form={form}
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.mainImageHelp">
                                        Upload in JPG, PNG format. Maximum file size allowed is 2MBs
                                    </Trans>
                                </FormHelperText>

                                {!!mainImage && !!mainImage.ID && this.renderImage(mainImage, this.onMainImageRemove.bind(this))}
                            </Grid>

                            <Grid item className="field-holder">
                                <h4><Trans i18nKey="myListings.editView.galleryImages">Gallery Images</Trans></h4>
                                <UploadField
                                    folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                                    onComplete={({ uploadFile }) => this.handleUploadGalleryImage(uploadFile)}
                                    disabled={galleryImages.length >= 10}
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.galleryImageHelp">
                                        Upload in JPG, PNG format. Maximum file size allowed is 2MBs
                                    </Trans>
                                </FormHelperText>

                                {!!galleryImages && !!galleryImages.length && galleryImages.map(
                                    (galleryImage, index) => this.renderImage(
                                        galleryImage,
                                        this.onGalleryImageRemove.bind(this),
                                        index
                                    )
                                )}

                            </Grid>

                            <Grid item className="field-holder">
                                <h4><Trans i18nKey="myListings.editView.videoLink">Video Link</Trans></h4>
                                <TextField
                                    form={form}
                                    name="VideoLink"
                                    variant="outlined"
                                    // label={<Trans i18nKey="myListings.editView.video">Video</Trans>}
                                    fullWidth
                                    placeholder="https://"
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.videoHelp">
                                        You can add youtube or vimeo video urls
                                    </Trans>
                                </FormHelperText>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderImage(image, onClickRemove, index) {
        return (
            <div className="uploaded-image-details">
                <div className="uploaded-image-detail">
                    <img src={image.ImageThumbnailURL} width={100} height={100} />
                    <Button title="Remove image" variant="link-red" onClick={(e) => onClickRemove(index)}>
                        Remove
                    </Button>
                </div>
            </div>
        );
    }

    onLogoRemove() {
        const { form } = this.props;
        form.setField({
            Logo: {
                ID: 0,
                AbsoluteLink: null,
                Name: null,
            },
            LogoID: 0,
        })
    }

    onMainImageRemove() {
        const { form } = this.props;
        form.setField({
            MainImage: {
                ID: 0,
                AbsoluteLink: null,
                Name: null,
            },
            MainImageID: 0,
        })
    }

    handleUploadGalleryImage(uploadFile) {
        const { form } = this.props;
        const GalleryImages = form.getField('GalleryImages');

        GalleryImages.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            ImageThumbnailURL: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        form.setField({ GalleryImages });
    };

    onGalleryImageRemove(index) {
        const { form } = this.props;
        const GalleryImages = form.getField('GalleryImages');
        delete GalleryImages[index];
        form.setField({ GalleryImages });
    };
}

export default (isEvent = false) => ({
    id: 'media',
    tabURL: `/${isEvent ? 'event' : 'listing'}/edit/:id/media`,
    label: 'Images & Media',
    component: withTranslation()(Media),
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle logo image save
        const logo = saveData.Logo;
        if (logo && logo.ID) {
            saveData.LogoID = logo.ID;
        }
        delete saveData.Logo;

        // handle main image save
        const mainImage = saveData.MainImage;
        if (mainImage && mainImage.ID) {
            saveData.MainImageID = mainImage.ID;
        }
        delete saveData.MainImage;

        // handle gallery images save
        if (saveData && saveData.GalleryImages) {
            const images = [];
            state.GalleryImages.forEach(function(image) {
                images.push(image.ID);
            });
            saveData.GalleryImages = images;
        }
    }
});