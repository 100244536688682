import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next'
import { Icon, Button } from '@material-ui/core';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import BasicDetails from '../Listings/Tabs/BasicDetails';
import Performances from './Tabs/Performances';
import Media from '../Listings/Tabs/Media';
import AdditionalInfo from '../Listings/Tabs/AdditionalInfo';
import Preview from "../Listings/Tabs/Preview";
import DealsAndAlerts from "../Listings/Tabs/DealsAndAlerts";
import Spinner from '../../components/loading/Spinner';
import ViewHeader from '../../components/header/ViewHeader';
import { Event } from "../../fragments/Event";


/**
 * Tab Definition
 */
const EventTabs = [
    BasicDetails(true),
    Performances,
    Media(true),
    DealsAndAlerts(true),
    AdditionalInfo(true),
    Preview(true),
];

class EventEdit extends Component {

    render() {
        const { match, config } = this.props;
        const includeTripAd = config.TripAdvisorExists === 'true';
        const includeQualmark = config.QualmarkExits === 'true';
        const includeBookit = config.BookitExists === 'true';
        const includeCustomFields = config.CustomFieldsExists === 'true';
        return (
            <Fragment>
                <Query query={query} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading) return <Spinner/>;

                        const listing = data && data.readOneListing;
                        if (!listing) return null;

                        return (
                            <Fragment>
                                {this.renderHeader(data && data.readOneListing)}

                                <Paper elevation={0} className='step-forms-container'>
                                    <DataFormView
                                        tabs={EventTabs}
                                        createNewFunc={{
                                            Title: null,
                                        }}
                                        objectType="Event"
                                        customFragment={Event(includeTripAd, includeQualmark, includeBookit, includeCustomFields)}
                                        itemId={match.params.id}
                                        name="Listing"
                                        onCreateNew={e => this.onCreateNew(e)}
                                        context={this}
                                        versioningMode="DRAFT"
                                        mutationName={'updatePortalEvent'}
                                        mutationInputType="ListingInput"
                                        extraFields={['Version', 'VersionForReview']}
                                        config={config}
                                    />
                                </Paper>
                            </Fragment>
                        );

                    }}
                </Query>
            </Fragment>
        );
    }

    renderHeader = (listing) => {
        if (!listing) return null;

        const { history } = this.props;
        return (
            <Grid item>
                <ViewHeader variant="record" title={listing.MainCategory.Title} subtitle={listing.Title}>
                    <Button onClick={() => history.push('/events')} variant="outlined">
                        <Icon>chevron_left</Icon>
                        <Trans i18nKey="backButton">
                            Back
                        </Trans>
                    </Button>
                </ViewHeader>
            </Grid>
        );
    };

    onCreateNew(listing) {
        const { history } = this.props;
        history.push(`listing/edit/${listing.ID}`);
    }
}

const query = gql`
    query readOneListing($id: ID!) {
        readOneListing(ID: $id, Versioning: { Mode: DRAFT}) {
            ID
            Title
            ClassNameShort
            MainCategory {
                Title
            }
        }
    }
`;

export default withTranslation()(EventEdit);
