import React, { Fragment } from 'react';
import Grid from '../../../components/layout/Grid';
import { ContactDetails } from "../../Listings/Tabs/ContactDetails";
import Accordion from "../../../components/accordion/Accordion";

class LocationDetailModal extends ContactDetails {

    render() {
        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        {this.renderLocationDetails()}

                        <Accordion
                            title="Map"
                            titleI18nKey="myListings.editView.tabMap"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderLocationMap()}
                        </Accordion>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

}

export default LocationDetailModal;