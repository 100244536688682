import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import './css/app.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Main from './views/Main';
import {isSignedIn} from './utils/sessions';
import { stringIsNullOrEmpty } from './utils/strings';
// import Unsplash, {toJson} from 'unsplash-js';
// import { unsplashAccessKey } from './environment';
import theme from './components/Theme';
import {Query} from "react-apollo";
import gql from "graphql-tag";
import Spinner from "./components/loading/Spinner";


// const unsplash = new Unsplash({ accessKey: unsplashAccessKey });

class App extends Component {
    state = {
        signedIn: false,
        drawerOpen: false,
        drawerMinimized: false,
    };


    componentWillMount() {
        this.updateState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    render() {
        const { signedIn } = this.state;
        const { location, history } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <div
                    className={signedIn ? 'app app-main' : 'welcome-screen'}
                    style={{backgroundImage: !signedIn && this.state.background ? 'url(' + this.state.background + ')' : false}}
                >



                    <Query query={siteConfigQuery}>
                        {results => {
                            const { loading, data } = results;

                            if (loading) return <Spinner />;

                            const config = data.readOneSiteConfig;

                            return (
                                <Fragment>

                                    {!!signedIn && <Header signedIn={signedIn} history={history} location={location} config={config} />}
                                    <div className={!signedIn? 'welcome-main': 'app-window'}>
                                        {signedIn
                                            ? (
                                                <div className='grid-fixer'>
                                                    <Main config={config} signedIn={signedIn} />
                                                </div>
                                            ) : (
                                                <Main config={config} signedIn={signedIn} />
                                            )
                                        }
                                    </div>
                                    {!!signedIn && <Footer signedIn={signedIn} config={config} />}
                                </Fragment>
                            )
                        }}
                    </Query>
                </div>
            </ThemeProvider>
        );
    }

    updateState() {
        const newState = {};
        const newSignedIn = isSignedIn();
        const { signedIn } = this.state;
        if (signedIn !== newSignedIn) {
            newState.signedIn = newSignedIn;
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    onToggleDrawerOpen = () => {
        const { drawerOpen } = this.state;
        this.setState({ drawerOpen: !drawerOpen });
    };

    onToggleDrawerMinimized = () => {
        const { drawerMinimized } = this.state;
        this.setState({ drawerMinimized: !drawerMinimized });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        const { setSnackbarMessage } = this.props;
        setSnackbarMessage('');
    };

    renderSnackbar = ({ snackbarMessage }) => {
        const open = !stringIsNullOrEmpty(snackbarMessage);
        return (
            <Snackbar
                message={<span id="message-id">{snackbarMessage}</span>}
                open={open}
                autoHideDuration={3000}
                onClose={this.handleCloseSnackbar}
                className="snackbar"
            />
        );
    };
}

// prettier-ignore
export default compose(
    withRouter,
    withStyles({})
)(App);

export const siteConfigQuery = gql`
    query ReadSiteConfig {
        readOneSiteConfig {
            SiteTitle
            SiteLink
            ListingURLPattern
            ListingPreviewURLPattern
            HomePageContent
            EnableLogoField
            TripAdvisorExists
            QualmarkExits
            BookitExists
            CustomFieldsExists
            PortalLogoURL
            PortalTranslations
            PortalTags {
                ID
                Title
            }
            PortalLinkGroups {
                ID
                Title
                FooterLinks {
                    ID
                    Title
                    Type
                    URL
                    Email
                    Phone
                    OpenInNewWindow
                }
            }
        }
    }
`;
