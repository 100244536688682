import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { getElementValueWrapper, onChangeWrapper } from '../../utils/objects';
import { getValidationDecorations } from '../../utils/validation';
import ReadonlyField from './ReadonlyField';

// This wraps the Select component to render either a
// native select or stylized select depending on breakpoints
class CustomSelect extends Component {
    state = {};

    render() {
        let {
            className,
            fullWidth,
            id,
            name,
            label,
            options,
            placeholder,
            controlProps,
            labelProps,
            selectProps,
            optionProps,
            menuItemProps,
            valueField,
            disabled,
            required,
            title,
            readOnly,
            allowNone = true
        } = this.props;

        id = id || (name ? `choose-${name}` : undefined);
        const inputProps = { id, name };
        if (selectProps && selectProps.inputProps) Object.assign(inputProps, selectProps.inputProps);

        let value = getElementValueWrapper(this);

        const decorations = getValidationDecorations(this.props);

        const commonSelectProps = {
            ...selectProps,
            value,
            onChange: e => onChangeWrapper(e, this),
            inputProps,
            //className: 'control',
            classes: { select: 'control' },
            placeholder,
            displayEmpty: true,
            className: `outer-control ${className || ''}`
        };

        if (readOnly) {
            const formValue = options.find(e => e.value === value);
            return <ReadonlyField label={label}>{(formValue && formValue.label) || value}</ReadonlyField>;
        }

        return (
            <span className={`select input `}>
                <FormControl
                    {...controlProps}
                    required={required}
                    disabled={disabled}
                    readOnly={readOnly}
                    fullWidth={fullWidth}
                    title={title}
                >
                    {label && (
                        <InputLabel
                            {...labelProps}
                            shrink
                            htmlFor={id}
                            className="label"
                            classes={{ shrink: `label--shrink ${decorations.cssClass}` }}
                        >
                            {decorations.validationIcon}
                            <span className="label-text">{label}</span>
                        </InputLabel>
                    )}
                    <Select {...commonSelectProps} native>
                            {allowNone ? <option value="">{placeholder || 'Please select...'}</option> : ''}
                        {!!options &&
                        options.map(o => {
                            const val = valueField ? o[valueField] : o.value;
                            return (
                                <option {...optionProps} key={val} value={val} disabled={o.disabled}>
                                    {o.label}
                                </option>
                            );
                        })}
                        </Select>
                    {decorations.errorMsg && (
                        <FormHelperText
                            error={decorations.inError}
                            classes={{
                                error: `validation-label ${decorations.cssClass}`
                            }}
                        >
                            {decorations.errorMsg}
                        </FormHelperText>
                    )}
                </FormControl>
            </span>
        );
    }
}

CustomSelect.defaultProps = {
    fullWidth: true,
    emptyValue: ''
};

export default withStyles({})(CustomSelect);
