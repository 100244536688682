import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Accordion extends Component {

    render() {
        const { title, titleI18nKey, defaultExpanded, children, className } = this.props;

        return (
            <ExpansionPanel defaultExpanded={defaultExpanded} className={className}>
                <ExpansionPanelSummary
                    className="panel-heading"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="basicbh-content"
                    id="basicbh-header"
                >
                    <Typography variant="h3">
                        <Trans i18nKey={titleI18nKey}>
                            {title}
                        </Trans>
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className='panel-contents'>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

}

export default withTranslation()(Accordion);
