import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {stringIsNullOrEmpty} from '../../utils/strings';
import {getBrandHomeByKey} from '../../utils/brands';

export default withStyles({})(({label, variant, legacyKey}) => {

        let cssVariant = null;

        const brand = getBrandHomeByKey(legacyKey);
        if (brand) {
			variant = brand.style;
			label = brand.label
        }
        if (!stringIsNullOrEmpty(variant))
            cssVariant = `table-key--${variant}`;

        return (
            <div className={`table-key ${cssVariant || ''}`}>
                {label}
            </div>
        );
    }
);

