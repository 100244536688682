import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '../layout/Grid';
import { withTranslation, useTranslation } from 'react-i18next';
import {getLink} from "../../utils/strings";


export default withTranslation()(({signedIn, config}) => {

    const { i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const getLanguage = () => {
        return i18n.language;
    };

    let cssClass = 'footer';
    if (signedIn) {
        cssClass += ' footer--black';
    }

    let languages = ['en'];
    if (config.PortalTranslations) {
        languages = JSON.parse(config.PortalTranslations);
    }

    return (
        <div className={cssClass}>
            <div className="app-container">
                <Grid container spacing={2} className={'footer__grid'}>
                    <Grid item xs={6} md={3}>
                        <div className="footer-group">
                            {!!config && !!config.SiteTitle && (
                                <h3>{config.SiteTitle}</h3>
                            )}

                            {!!config && languages.length > 1 && (
                                <p className='footer-group__lang-buttons'>
                                    {languages.map(translation => {
                                        return <Button onClick={() => changeLanguage(translation)}
                                                className={{'active': getLanguage() === translation}}>{translation}</Button>
                                    })}
                                </p>
                            )}
                        </div>
                    </Grid>

                    {!!config && !!config.PortalLinkGroups.length && config.PortalLinkGroups.map(group => (
                        <Grid item xs={6} md={3} key={'link-group' + group.ID}>
                            <div className="footer-group">
                                <h4>{group.Title}</h4>
                                <ul>
                                    {group.FooterLinks.map(linkObj => (
                                        <li>
                                            <a href={getLink(linkObj)} rel="noopener noreferrer" target={linkObj.OpenInNewWindow ? '_blank' : '_self'}>
                                                {linkObj.Title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )}
);
