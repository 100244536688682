import { API_BASE_URL} from "./_configs/env";

export const introspectionQueryResultData = require('./fragments/introspection/fragmentTypesDev');
export const serverUrl = API_BASE_URL;
export const listingPreviewBase = API_BASE_URL + '/explore/preview';

export const maxUploadSize = 10485760; // 10MB

export const unsplashAccessKey = '13b896ba78fd3d36cfdcef723f7dd94d30cfed838b62fdf745575f0c5cb781e3';

export const tinyMceAPIKey = 'tsnfwf3i4ybd44ombo6nlpz8p6w13mjs7dy5ymopa5vl47ce';