import React, { Component, Fragment } from 'react';
import {getToken} from "../../utils/sessions";
import Grid from "../../components/layout/Grid";
import Spinner from "../../components/loading/Spinner";
import {listingPreviewBase} from "../../environment";

class ListingPreview extends Component {

    state = {
        loading: true
    };

    render() {
        const { loading } = this.state;
        const { config, form } = this.props;

        let previewLink = config.ListingPreviewURLPattern;
        if (!previewLink || !previewLink.includes('[SLUG]')) return null;

        previewLink = previewLink.replace('[SLUG]', form.getField('URLSegment'))

        return (
            <Grid item>
                {
                    (() => {
                        const token = getToken();
                        if (token && token.value) {
                            const url = `${previewLink}?token=${token.value}`;
                            return (
                                <Fragment>
                                    {loading && <Spinner />}

                                    <iframe
                                        className={loading && 'hidden'}
                                        width="100%"
                                        height="800" src={url}
                                        ref="iframe"
                                        onLoad={this.hidePreviewLoader.bind(this)}
                                    />
                                </Fragment>
                            )
                        }
                    })()
                }
            </Grid>
        );
    }

    hidePreviewLoader() {
        this.setState({
            loading: false
        });
    }

}

export default ListingPreview;