import React, { Fragment }  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { Mutation } from 'react-apollo';
import { getAuthClient } from '../../utils/apollo';
import TextField from '../../components/form/TextField';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Register from './Register';
import {setToken} from "../../utils/sessions";
import {joinDefined} from "../../utils/strings";

class ResetPassword extends Register {

    render() {
        const { config } = this.props;

        return (
            <Fragment>
                <div className="sign-in">
                    <div className="sign-in__header">
                        <img src={config.PortalLogoURL} className="black" alt={config.SiteTitle} />
                    </div>
                    <Card className='welcome-card'>
                        {this.renderContent()}
                    </Card>
                    <div className="sign-in__footer">
                        <p>Solution by <a href="https://konduitapp.com" target="_blank">Konduit</a></p>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderContent() {
        const { verificationPending, verified } = this.state;

        if (verificationPending) {
            return (
                <Fragment>
                    <Mutation
                        client={getAuthClient()}
                        mutation={verifyMemberMutation}
                        onCompleted={this.onMemberVerification}
                        onError={e => {
                            this.setState({verifyError: e.message.replace('GraphQL error: ', '')})
                        }}
                    >
                        {this.renderVerificationForm}
                    </Mutation>

                    <Mutation
                        client={getAuthClient()}
                        mutation={sendVerificationMutation}
                        onCompleted={this.onResendCompleted}
                        onError={e => {
                            this.setState({error: e.message.replace('GraphQL error: ', '')})
                        }}
                    >
                        {this.renderResendVerifyForm}
                    </Mutation>
                </Fragment>
            );
        } else if (verified) {
            return (
                <Mutation
                    client={getAuthClient()}
                    mutation={changePasswordMutation}
                    onCompleted={this.onChangePasswordCompleted}
                    onError={e => {
                        this.setState({error: e.message.replace('GraphQL error: ', '')})
                    }}
                >
                    {this.renderChangePasswordForm}
                </Mutation>
            );
        } else {
            return (
                <Mutation
                    client={getAuthClient()}
                    mutation={sendVerificationMutation}
                    onCompleted={this.onVerificationSent}
                    onError={e => {
                        this.setState({error: e.message.replace('GraphQL error: ', '')})
                    }}
                >
                    {this.renderLostPasswordForm}
                </Mutation>
            )
        }
    }

    renderChangePasswordForm = (mutate, results = {}) => {
        const { data, loading } = results;
        const { email, password, confirmPassword , code, verifyError} = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;
            mutate({
                variables: { email, code, password }
            });
        };

        return (
            <ValidatorForm
                ref="form"
                onSubmit={onSubmit}
                onError={errors => console.log(errors)}
                className="sign-in login-popup"
            >
                <h3>
                    Change password
                </h3>
                {verifyError && (
                    <p className="form-message form-message--bad">
                        {verifyError}
                    </p>
                )}
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('password')}
                        label="Password"
                        value={password}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('confirmPassword')}
                        label="Confirm Password"
                        value={confirmPassword}
                        type="password"
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={['password mismatch', 'this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='action-buttons'>
                    <Button type="submit" variant="contained" className="button-base primary">
                        Change
                    </Button>
                </div>
            </ValidatorForm>
        );
    };

    renderLostPasswordForm = (mutate, results = {}) => {
        const { data, loading } = results;
        const { email, verifyError } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;
            mutate({
                variables: { email }
            });
        };

        return (
            <form className="sign-in login-popup" onSubmit={onSubmit}>
                <h3>
                    Reset your password
                </h3>
                <p>
                    Enter your e-mail address and we will send you a verification code with which you can reset your password
                </p>
                {verifyError && (
                    <p className="form-message form-message--bad">
                        {verifyError}
                    </p>
                )}
                <div className='field-holder'>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('email')}
                        label="Email"
                        value={email}
                        inputMode="email"
                        variant="outlined"
                    />
                </div>
                <div className='action-buttons'>
                    <Button type="submit" variant="contained" className="button-base primary">
                        Send
                    </Button>
                </div>
                <p>Or go back to <a href={'/'}>login</a>.</p>
            </form>
        );
    };

    onChangePasswordCompleted = data => {
        const { Token, Member, Message } = (data && data.changePassword) || {};
        if (!Token) {
            this.setState({ verifyError: Message ? Message : 'Error occurred. Try again.'});
            return;
        }
        if (!Token) return;
        const { ID, FirstName, Surname } = Member;
        setToken({
            userid: ID,
            value: Token,
            name: joinDefined([FirstName, Surname], ' ')
        });
        const { location, history } = this.props;
        const redirectPath = (location.state && location.state.referrer) || '/';
        history.push(redirectPath);
    };

    onVerificationSent = data => {
        const member = data && data.sendMemberVerification;
        if (!member) {
            this.setState({ verifyError: 'The email address you entered couldn\'t be found.'});
            return;
        }

        localStorage.setItem('verification_pending', 1);
        localStorage.setItem('verifying_email', member.Email);
        this.setState({ verificationPending: true });
    };

    onMemberVerification = data => {
        const member = (data && data.verifyMember) || {};
        if (!member) {
            this.setState({ verifyError: 'The email address you entered couldn\'t be found.'});
            return;
        }

        localStorage.removeItem('verification_pending');
        localStorage.removeItem('verifying_email');
        this.setState({
            verificationPending: false,
            verified: true,
        });
    };
}

export const verifyMemberMutation = gql`
    mutation VerifyMember($code: String!) {
        verifyMember(VerificationCode:$code) {
            ID
            Email
            FirstName
        }
    }
`;

export const sendVerificationMutation = gql`
    mutation SendMemberVerificationCode($email: String!, $newEmail: String) {
        sendMemberVerification(Email: $email, NewEmail: $newEmail) {
            ID
            Email
        }
    }
`;

export const changePasswordMutation = gql`
    mutation ChangePassword($email: String!, $password: String!, $code: String!) {
        changePassword(Email: $email, Password: $password, VerificationCode: $code) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
            Message
        }
    }
`;

export default withStyles({})(ResetPassword);
