import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { withTranslation, Trans } from 'react-i18next'
import Table, { Cell, Row } from '../table/Table';
import Inline, { inlineAlignment } from '../layout/Inline';
import Button from '../form/Button';
import Grid from '../layout/Grid';
import LinearProgressIndicator from '../loading/LinearProgressIndicator';
import { countTo, deleteTypeName, getProperty, isNullOrUndefined } from '../../utils/objects';
import { createTableQuery } from './DataTableConstants';
import AlertBar from '../form/AlertBar';
import { stringIsNullOrEmpty } from '../../utils/strings';
import BackArrow from '../icon/BackArrow';
import NextArrow from '../icon/NextArrow';
import { BRANDS, getBrandHomeByKey } from '../../utils/brands';
import SearchWithButton from '../form/SearchWithButton';
import TableKey from '../table/TableKey';
import Spinner from '../loading/Spinner';
import Checkbox from '../form/Checkbox';
import { indexOf } from '../../utils/arrays';

/*
todo: pull the sortable options from the query and save them in the state
render which columns are sortable/filterable
change variables and refetch

show pagination options, pull pagination from variables
*/
class DataTable extends Component {
    state = {
        variables: null,
        query: null,
        showFilterPop: false,
        filterBy: {}
    };

    componentWillMount() {
        const { queryName, fragment, variables, dataObject, filterByFragment, isSortable, versioningMode } = this.props;

        this.setState({
            query: createTableQuery(
                queryName,
                fragment,
                dataObject,
                filterByFragment,
                isSortable,
                variables.memberID,
                variables.expired,
                versioningMode
            ),
            variables: {
                ...variables,
                offset: (variables && variables.offset) || 0,
                limit: (variables && variables.limit) || 10,
                contains: (variables && variables.contains) || '',
                filterBy: (variables && variables.filterBy) || '',
                sortBy: (variables && variables.sortBy) || []
            }
        });
    }

    render() {
        const { query, variables } = this.state;
        return (
            <div className="data-table">
                <Query query={query} variables={variables} fetchPolicy="cache-and-network">
                    {({ data, loading, error }) => {
                        if (error) return this.renderError('an error occurred executing the query', error);

                        if (!loading && isNullOrUndefined(data)) return this.renderError('missing data', data);

                        const propertyName = Object.keys(data)[0];

                        if (!loading && isNullOrUndefined(propertyName)) {
                            return this.renderError('object type was not returned with data', data);
                        }

                        if (loading && isNullOrUndefined(propertyName)) return this.renderLoading();

                        if (isNullOrUndefined(data[propertyName].edges))
                            return this.renderError('query requires pagination to be enabled');

                        const sortInfo = getProperty(data, '__type.inputFields.0.type.ofType.enumValues');
                        return this.renderTable(data[propertyName], loading, sortInfo);
                    }}
                </Query>
            </div>
        );
    }

    renderError(message, obj) {
        // eslint-disable-next-line no-console
        console.error(message, obj);
        return <AlertBar variant="error">{message}</AlertBar>;
    }

    renderLoading() {
        return <LinearProgressIndicator />;
    }

    renderTable(data, loading, sortInfo) {
        const {
            columns,
            searchVariable,
            tableTitle,
            filterByFragment,
            showHeader,
            hidePagination,
            noResultsMessage,
            className,
            suffixButtonLink,
            suffixButtonText
        } = this.props;
        const { variables } = this.state;
        const sortBy = variables.sortBy;

        return (
            <Fragment>
                {(showHeader && (searchVariable || tableTitle || filterByFragment)) && this.renderHeader(data)}
                <Grid container>
                    {(!!data.edges.length || loading) && (
                        <Grid className='table-holder'>
                            {!!data.edges.length && (
                                <Table
                                    columns={columns}
                                    className={className}
                                    sortableHeaderCell={column => this.renderSortableHeaderCell(column, sortInfo, sortBy)}
                                >
                                    {data.edges.map(({ node }, rowIndex) => this.renderRow(node, columns, rowIndex))}
                                </Table>
                            )}
                            {loading && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        background: '#FFFFFF99'
                                    }}
                                >
                                    <Spinner />
                                </div>
                            )}
                        </Grid>
                    )}
                    {!data.edges.length && (
                        <Grid className='table-holder'>
                            <p>
                                {noResultsMessage ||
                                    'There are currently no results' +
                                    searchVariable && variables[searchVariable] && ' for your query. Try other words or check the spelling.'
                                }

                            </p>
                        </Grid>
                    )}
                    {!hidePagination && <div className={'pagination'}>{this.renderPagination(data)}</div>}

                    {!!data.edges.length && suffixButtonLink && suffixButtonText && (
                        <div className={'table-suffix'}>
                            <a href={suffixButtonLink}>
                                {suffixButtonText}
                                <NextArrow />
                            </a>
                        </div>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderHeader(data) {
        const { tableTitle, brandProperty } = this.props;
        const { totalCount } = data.pageInfo;
        const { variables } = this.state;
        const filterByInfo = data.filterByInfo && deleteTypeName(data.filterByInfo);
        return (
            <Grid container className={'data-table__header'}>
                <Grid className={'data-table__header__inner'}>
                    <div>
                        <h4>
                            {tableTitle ? tableTitle + ' - ' : ''} {totalCount} Item{totalCount === 1 ? '' : 's'}
                        </h4>
                    </div>
                    <SearchWithButton
                        placeholder="Search ..."
                        searchKeyword={variables.contains}
                        onSearchSubmit={s => this.setSearch(s)}
                    />
                </Grid>
            </Grid>
        );
    }

    renderSortableHeaderCell(column, sortInfo, sortBy) {
        if (!sortInfo) return null;
        const isSortable = indexOf(sortInfo, x => {
            return x.name === column.propertyPath;
        });
        if (isSortable === -1) {
            return column.label;
        }

        const currentSort = sortBy && sortBy.length ? sortBy[0] : {};

        const currentDirection =
            currentSort.direction && currentSort.direction.length ? currentSort.direction.toLowerCase() : undefined;

        return (
            <TableSortLabel
                active={currentSort.field === column.propertyPath}
                direction={currentDirection}
                onClick={() => {
                    this.setSortBy(column);
                }}
            >
                {column.label}
            </TableSortLabel>
        );
    }

    renderRow(row, columns, rowIndex) {
        const { onClickRow, brandProperty } = this.props;
        const property = getProperty(row, brandProperty) || brandProperty;
        const brand = brandProperty ? getBrandHomeByKey(property) : '';

        return (
            <Row
                pad
                variant={brand && brand.style}
                key={'table-row-' + row.ID}
                onClick={onClickRow ? () => onClickRow(row) : undefined}
            >
                {columns.map(column => this.renderCell(row, column, rowIndex))}
            </Row>
        );
    }

    renderCell(row, column, rowIndex) {
        const value = !stringIsNullOrEmpty(column.propertyPath) ? getProperty(row, column.propertyPath) : null;

        return (
            <Cell key={column.label + '--' + row.ID} dataLabel={column.label} hiddenSm={column.hiddenSm}>
                {!isNullOrUndefined(column.renderCell) ? column.renderCell(row, value, rowIndex) : value}
            </Cell>
        );
    }

    renderPagination(data) {
        const { totalCount, hasNextPage, hasPreviousPage } = data.pageInfo;

        if (!(hasNextPage || hasPreviousPage)) {
            return null;
        }

        const {
            variables: { offset, limit }
        } = this.state;
        let counter = 1;

        const division = totalCount / limit;
        const floor = Math.floor(division);
        const numberOfPages = division === floor ? floor : floor + 1;
        const current = offset / limit + 1;

        return (
            <Fragment>
                <Inline className="pagination__buttons button-alignment">
                    {hasPreviousPage && (
                        <Button onClick={() => this.previousOffset()} className="pagination__button" variant="pagination-primary">
                            <BackArrow className="icon" />
                        </Button>
                    )}

                    {/*for fist page*/}
                    <Fragment>
                        <Button
                            className="pagination__button"
                            variant={current === 1 ? 'pagination-current' : 'pagination-primary'}
                            onClick={() => this.setOffset(0)}
                        >
                            1
                        </Button>

                        {numberOfPages > 9 && current > 5 && <span>...</span>}
                    </Fragment>

                    {countTo(numberOfPages - 2).map(page => {
                        // (numberOfPages - 2) to skip first and last
                        page += 2; // start from 2
                        const position = (page - 1) * limit;
                        if (
                            counter <= 7 &&
                            (page >= current - 3 || (current > numberOfPages - 3 && numberOfPages - page < 7))
                        ) {
                            counter++;
                            return (
                                <Button
                                    key={page}
                                    className="pagination__button"
                                    variant={page === current ? 'pagination-current' : 'pagination-primary'}
                                    onClick={() => this.setOffset(position)}
                                >
                                    {page}
                                </Button>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {/*for last page*/}
                    <Fragment>
                        {numberOfPages > 9 && current < numberOfPages - 4 && <span>...</span>}

                        <Button
                            className="pagination__button"
                            variant={numberOfPages === current ? 'pagination-current' : 'pagination-primary'}
                            onClick={() => this.setOffset((numberOfPages - 1) * limit)}
                        >
                            {numberOfPages}
                        </Button>
                    </Fragment>

                    {hasNextPage && (
                        <Button onClick={() => this.nextOffset()}
                                className="pagination__button"
                                variant="pagination-primary">
                            <NextArrow className="icon" />
                        </Button>
                    )}
                </Inline>
            </Fragment>
        );
    }

    handleCheckboxChange = key => event => {
        const { filterBy } = this.state;
        const { value, checked } = event.target;

        if (checked) {
            if (!(key in filterBy)) {
                filterBy[key] = [];
            }
            filterBy[key].push(value);
        } else {
            let index = filterBy[key].indexOf(value);
            if (index > -1) {
                filterBy[key].splice(index, 1);
            }
        }
        this.setState({
            filterBy: filterBy
        });
    };

    setSortBy = column => {
        const { variables } = this.state;
        let newVars = { ...variables };
        const currentSort = newVars.sortBy && newVars.sortBy.length ? newVars.sortBy[0] : {};
        let newSortBy = { direction: 'DESC' };
        let changeDirection = currentSort.field === column.propertyPath;

        if (changeDirection) {
            newSortBy.direction = currentSort.direction === 'DESC' ? 'ASC' : 'DESC';
        }
        newSortBy.field = column.propertyPath;

        newVars.sortBy = [newSortBy];

        this.setState({
            variables: newVars
        });
    };

    setFilterBys = () => {
        const { filterBy, variables } = this.state;
        const newVars = { ...variables };

        //need to massage the filterBy data
        if (Object.keys(filterBy).length) {
            let formattedFilterByKeys = [];
            const filterByKeys = Object.keys(filterBy);
            for (let filterKey of filterByKeys) {
                formattedFilterByKeys.push({
                    field: filterKey,
                    value: { ...filterBy[filterKey] }
                });
            }
            newVars.filterBy = formattedFilterByKeys;
        }

        this.setState({
            showFilterPop: false,
            variables: newVars
        });
    };

    setSearch = searchTerm => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.contains = searchTerm;
        this.setState({
            variables: newVars
        });
    };

    setOffset = page => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = page;
        this.setState({
            variables: newVars
        });
    };

    nextOffset = () => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = variables.offset + variables.limit;
        this.setState({
            variables: newVars
        });
    };

    previousOffset = () => {
        const { variables } = this.state;
        const newVars = { ...variables };
        newVars.offset = variables.offset - variables.limit;
        this.setState({
            variables: newVars
        });
    };
}

export default withTranslation()(DataTable);
