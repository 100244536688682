import React, {Component} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import { getElementValueWrapper, onChangeWrapper } from '../../utils/objects';
import { getValidationDecorations } from '../../utils/validation';
import { joinDefined } from '../../utils/strings';
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "../layout/Grid";

class FormRadioGroup extends Component {
    state = {};

    render() {
        const {
            className,
            name,
            row,
            form,
            value,
            options,
            label,
            onChange,
            onChanging,
            labelProps,
            groupProps,
            radioProps,
            labelField,
            valueField,
            disabled,
            readOnly,
            validationResult,
            ...other
        } = this.props;

        const id = `radio-${name}`;
        const { className: labelClassName, ...otherLabelProps } = labelProps;
        const { className: groupClassName, ...otherGroupProps } = groupProps;
        const currentValue = !!getElementValueWrapper(this) ? getElementValueWrapper(this) : '';
        const decorations = getValidationDecorations(this.props);

        return (
            <FormControl
                className={`radio-group ${joinDefined(
                    [className, decorations.cssClass, disabled ? 'disabled' : null],
                    ' '
                )}`}
                role="group"
                aria-labelledby={id}
                disabled={disabled}
                {...other}
            >
                {/*{label && <Label id={id} text={label} {...otherLabelProps} />}*/}
                <FormLabel>{label}</FormLabel>
                <Grid container spacing={0}>
                    <RadioGroup
                        className={groupClassName}
                        aria-label={label}
                        name={name}
                        value={currentValue}
                        onChange={!readOnly && (e => onChangeWrapper(e, this))}
                        row={row}
                        {...otherGroupProps}
                    >
                        {options.map(o => {
                            const lbl = labelField ? o[labelField] : o.value;
                            const val = valueField ? o[valueField] : o.value;
                            return (
                                <FormControlLabel
                                    key={val}
                                    value={val}
                                    label={lbl}
                                    classes={{ label: 'form-label' }}
                                    control={
                                        <Radio
                                            className={currentValue === val ? 'checked' : ''}
                                            color="primary"
                                            checked={currentValue === val}
                                            {...radioProps}
                                        />
                                    }
                                    {...o}
                                />
                            );
                        })}
                    </RadioGroup>
                    {decorations.inError && (
                        <FormHelperText
                            error={decorations.inError === true}
                            classes={{
                                error: `validation-label ${decorations.cssClass}`
                            }}
                        >
                            {decorations.errorMsg}
                        </FormHelperText>
                    )}
                </Grid>
            </FormControl>
        );
    }
}

FormRadioGroup.defaultProps = {
    row: true,
    fullWidth: false,
    labelProps: {},
    groupProps: {},
    radioProps: {}
};

export default withStyles({})(FormRadioGroup);
