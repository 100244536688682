import React, { Fragment } from 'react';
import { Trans } from 'react-i18next'
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Accordion from "../../../components/accordion/Accordion";
import { Listing } from "../../../fragments/Listing";
import ListingTab from "./ListingTab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "../../../components/form/Checkbox";
import CheckboxSetField from "../../../components/form/CheckboxSetField";
import {FormHelperText} from "@material-ui/core";

class BusinessInfo extends ListingTab {

    state = {
        openingHours: [
            {
                Day: 'Sunday',
                From: '',
                To: '',
            },
            {
                Day: 'Monday',
                From: '',
                To: '',
            },
            {
                Day: 'Tuesday',
                From: '',
                To: '',
            },
            {
                Day: 'Wednesday',
                From: '',
                To: '',
            },
            {
                Day: 'Thursday',
                From: '',
                To: '',
            },
            {
                Day: 'Friday',
                From: '',
                To: '',
            },
            {
                Day: 'Saturday',
                From: '',
                To: '',
            },
        ],
        operatingMonths: {
            January: false,
            February: false,
            March: false,
            April: false,
            May: false,
            June: false,
            July: false,
            August: false,
            September: false,
            October: false,
            November: false,
            December: false,
        }
    };



    componentDidMount() {
        const { form } = this.props;

        const { openingHours } = this.state;
        const hours = !!form.getField('OpeningHours') ? JSON.parse(form.getField('OpeningHours')) : null;
        if (hours) {
            openingHours.map((obj, index) => {
                this.state.openingHours[index].From = hours[[`${obj.Day}OpeningFrom`]];
                this.state.openingHours[index].To = hours[[`${obj.Day}OpeningTo`]];
            });
            this.forceUpdate();
        }

        const { operatingMonths } = this.state;
        const months = !!form.getField('OperatingMonths') ? JSON.parse(form.getField('OperatingMonths')) : null;
        if (months) {
            for (let month in operatingMonths) {
                this.state.operatingMonths[month] = months[month];
            }
            this.forceUpdate();
        }
    }

    render() {
        const { form } = this.props;
        const { openingHours, operatingMonths } = this.state;
        const objectType = form.getField('ClassNameShort');
        const customOpeningHours = <Trans i18nKey="myListings.editView.customOpeningHours">Custom Opening Hours</Trans>;
        const checkIn = <Trans i18nKey="myListings.editView.checkIn">Check In</Trans>;
        const checkOut = <Trans i18nKey="myListings.editView.checkOut">Check Out</Trans>;

        const onMonthChange = (options) => {
            options.map(o => {
                this.state.operatingMonths[o.label] = o.checked;
            })
            this.forceUpdate();

            form.setField({ OperatingMonths: this.state.operatingMonths });
        };

        const monthOptions = Object.keys(operatingMonths).map(month => {
            return {
                label: month,
                checked: operatingMonths[month],
                id: month
            }
        });


        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Operating Info"
                            titleI18nKey="myListings.editView.operatingInfo"
                            className='form-panel'
                            defaultExpanded
                        >
                            <Grid container spacing={0}>
                                <Grid item className="field-holder">
                                    <FormControlLabel
                                        control={<Checkbox form={form} name="IsTwentyFourSeven" color="primary" />}
                                        label="Is twenty four seven"
                                    />
                                </Grid>

                                {!form.getField('IsTwentyFourSeven') && (
                                    <Grid item md={8} className="opening-hours field-holder">
                                        <FormLabel>
                                            <Trans i18nKey="myListings.editView.hours">
                                                Opening Hours
                                            </Trans>
                                        </FormLabel>

                                        <table cellSpacing={0} cellPadding={0} className='opening-hours__table'>
                                            <thead>
                                            <tr>
                                                <th className='opening-hours__day'>&nbsp;</th>
                                                <th className='opening-hours__input'>From</th>
                                                <th className='opening-hours__input'>To</th>
                                                <th className='opening-hours__actions'>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {openingHours.map((obj, index) => this.renderOpeningHoursDay(obj, index))}
                                            </tbody>
                                        </table>
                                    </Grid>
                                )}

                                <Grid item className="field-holder">
                                    <TextField
                                        form={form}
                                        name="CustomOpeningHours"
                                        variant="outlined"
                                        label={customOpeningHours}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item className="field-holder">
                                    <CheckboxSetField
                                        id="field-operating months"
                                        fieldLabel="Operating Months"
                                        onChange={(options) => onMonthChange(options)}
                                        options={monthOptions}
                                    />
                                </Grid>

                                {objectType === 'Accommodation' && (
                                    <Fragment>
                                        <Grid item className="field-holder">
                                            <TextField
                                                form={form}
                                                name="CheckIn"
                                                type="time"
                                                variant="outlined"
                                                label={checkIn}
                                                fullWidth
                                            />
                                            <FormHelperText>
                                                eg: 10:00 AM
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item className="field-holder">
                                            <TextField
                                                form={form}
                                                name="CheckOut"
                                                type="time"
                                                variant="outlined"
                                                label={checkOut}
                                                fullWidth
                                            />
                                            <FormHelperText>
                                                eg: 6:00 PM
                                            </FormHelperText>
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderOpeningHoursDay(obj, index) {
        const { form } = this.props;
        const { openingHours } = this.state;

        const handleCopyDayBefore = () => {
            onTimeFieldChange(this.state.openingHours[index-1].From, 'From');
            onTimeFieldChange(this.state.openingHours[index-1].To, 'To');
        };

        const onTimeFieldChange = (value, name) => {
            this.state.openingHours[index][name] = value;
            this.forceUpdate();

            form.setField({ OpeningHours: this.state.openingHours });
        };

        return (
            <tr>
                <td className='opening-hours__day'>{obj.Day}</td>
                <td className='opening-hours__input'>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="time"
                        value={obj.From}
                        onChange={e => onTimeFieldChange(e.target.value, 'From')}
                    />
                </td>
                <td className='opening-hours__input'>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="time"
                        value={obj.To}
                        onChange={e => onTimeFieldChange(e.target.value, 'To')}
                    />
                </td>
                <td className='opening-hours__actions'>
                    {index > 0 && (openingHours[index-1].From || openingHours[index-1].To) && (
                        <Button variant="outlined" onClick={e => handleCopyDayBefore()}>
                            Copy day before
                        </Button>
                    )}
                </td>
            </tr>
        );
    }

}

export default {
    id: 'hoursAndRates',
    tabURL: '/listing/edit/:id/business-details',
    label: 'Business Details',
    component: BusinessInfo,
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle opening hours save
        const openingHours = state.OpeningHours;
        if (openingHours && Array.isArray(openingHours)) {
            const hours = {};
            openingHours.map(obj => {
                hours[[`${obj.Day}OpeningFrom`]] = obj.From;
                hours[[`${obj.Day}OpeningTo`]] = obj.To;
            });
            saveData.OpeningHours = JSON.stringify(hours);
        }

        // handle operating monthss save
        const operatingMonths = state.OperatingMonths;
        if (operatingMonths && operatingMonths.hasOwnProperty('January')) {
            const months = {};
            Object.keys(operatingMonths).map((key, index) => {
                months[key] = !!operatingMonths[key];
            })

            saveData.OperatingMonths = JSON.stringify(months);
        }
    }
};