import React, { Fragment } from 'react';
import {withStyles} from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography';
import { isNullOrUndefined } from 'util';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Separator from '../layout/Separator';
import { orientationMode } from '../../utils/objects';
import Inline from '../layout/Inline';

const ViewHeader = ({ children, title, subtitle, variant, titleTag, className }) => {

    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) {
        cssVariant = `view-header--${variant}`;
    }

    let tag = 'h3';
    if (!stringIsNullOrEmpty(titleTag)) {
        tag = titleTag;
    }

    return (
        <div className={`view-header ${cssVariant || ''} ${className || ''}`}>
            <Inline className="view-header__inner">
                <Typography className="title" variant={tag}>
                    {title}
                </Typography>
                {!stringIsNullOrEmpty(subtitle) &&
                <Fragment>
                    <Separator orientation={orientationMode.vertical} />
                    <Typography className="subtitle" variant="h4" subtitle>
                        {subtitle}
                    </Typography>
                </Fragment>

                }
            </Inline>
            {!isNullOrUndefined(children) &&
                <div className="header-children">
                    {children}
                </div>
            }
        </div>
    );
};

export default withTranslation()(ViewHeader);
