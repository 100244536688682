import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '../../../components/layout/Grid';
import TextField from "../../../components/form/TextField";

class PerformanceModal extends Component {

    render() {
        const { form } = this.props;
        return (
            <Grid container>
                <Grid item>
                    <div className='field-holder'>
                        <FormLabel>Start Date</FormLabel>
                        <TextField
                            type="datetime-local"
                            fullWidth
                            variant="outlined"
                            form={form}
                            name={`StartDate`}
                        />
                    </div>

                    <div className='field-holder'>
                        <FormLabel>End Date</FormLabel>
                        <TextField
                            type="datetime-local"
                            fullWidth
                            variant="outlined"
                            form={form}
                            name={`EndDate`}
                        />
                    </div>

                    <div className='field-holder'>
                        <FormLabel>Venue</FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            form={form}
                            name={`Venue`}
                        />
                    </div>

                    <div className='field-holder'>
                        <FormLabel>Prices</FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            form={form}
                            name={`Prices`}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default PerformanceModal;