import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

const Anonymous = ({ children }) => (
	<div className="anonymous">
		<div className="side side--left">
			<Typography variant="h2" align="center" className="title">
				Destination database
				<br />
				Portal
			</Typography>
		</div>
		<div className="side side--right">{children}</div>
	</div>
);

export default withStyles({})(Anonymous);
