import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '../../../components/layout/Grid';
import {getCategoriesTree} from "../../../components/form/CategoryTreeField";
import { withTranslation, Trans } from 'react-i18next'
import {getUser} from "../../../utils/sessions";
import Modal from '../../../components/modal/Modal';
import Spinner from '../../../components/loading/Spinner';
import GroupedSelect from "../../../components/form/GroupedSelect";

class CreateListingModal extends Component {
    state = {
        isCategoriesLoading: true,
        groups: [],
        name: '',
        categoryID: 0,
        categoriesLoaded: false,
    };

    componentDidMount() {
        const { isEvent } = this.props;
        const that = this;

        getCategoriesTree().then(categories => {
            const groups = {
                'Other': []
            };

            const loop = (items, group) => items.filter(e => !!isEvent ? e.ClassType === 'Event' : e.ClassType !== 'Event').map((item) => {

                if (item.Children && item.Children.length) {
                    loop(item.Children, item.Title);
                } else {
                    if (!groups.hasOwnProperty(group)) {
                        groups[group] = [];
                    }
                    groups[group].push(item);
                }
            });

            loop(categories, 'Other');

            that.setState({
                groups: groups,
                isCategoriesLoading: false,
            });
        });
    }

    render() {
        const { open, onClose } = this.props;
        return (
            <Modal open={open} title="Create a New Listing" subtitle="Please select a category">
                <Grid container spacing={0}>
                    <Grid item>
                        <Mutation mutation={listingCreateMutation} onCompleted={this.onCompleted}>
                            {this.renderForm}
                        </Mutation>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { loading } = results;
        const { planID, onClose } = this.props;

        const { name, categoryID, groups, isCategoriesLoading } = this.state;
        const error = '';

        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;

            mutate({
                variables: {
                    categoryID: categoryID,
                    planID: planID,
                    memberID: getUser().ID
                }
            });
        };

        return (
            <form
                className=""
                onSubmit={onSubmit}
                ref="form"
            >
                <div className='field-holder'>
                    {isCategoriesLoading ? (
                        <Spinner/>
                    ) : (
                        <GroupedSelect
                            options={groups}
                            label="Main Category"
                            required
                            onChange={e => this.setState({ categoryID: e.target.value })}
                        />
                    )}
                </div>
                <div className='action-buttons'>
                    <div className='button-flex'>
                        <Button variant="outlined" onClick={e => onClose()}>
                            <Trans i18nKey="buttonCancel">
                                Cancel
                            </Trans>
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            className="button-base primary"
                            disabled={!categoryID || loading}
                        >
                            <Trans i18nKey="myListings.createView.button">
                                Create
                            </Trans>
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    onChangeText = name => e => {
        this.setState({ [name]: e.target.value });
    };


    onCompleted = data => {
        const { isEvent } = this.props;
        const listing = (data && data.createPortalListing);
        if (!listing) return;

        const { history } = this.props;
        history.push(`/${isEvent ? 'event' : 'listing'}/edit/${listing.ID}`);
    };

}

export const listingCreateMutation = gql`
    mutation CreateListing($categoryID: ID!, $memberID: ID!) {
        createPortalListing(MainCategoryID: $categoryID, MemberID: $memberID) {
            ID
        }
    }
`;

export default withTranslation()(CreateListingModal);