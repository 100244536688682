import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles';
import { Icon, Button } from '@material-ui/core';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import TextField from "../../components/form/TextField";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { Mutation } from 'react-apollo';
import Accordion from "../../components/accordion/Accordion";
import Spinner from "../../components/loading/Spinner";
import Member from "../../fragments/Member";
import {getUser} from "../../utils/sessions";
import {getAuthClient, getClient} from "../../utils/apollo";

class Account extends Component {

    state = {
        email: '',
        firsName: '',
        surname: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        loading: true,
        beingSaved: false,
    };

    componentDidMount() {
        const that = this;
        getClient()
            .query({
                query: readMemberQuery,
                variables: { ID: getUser().ID }
            })
            .then(result => {
                if (result && result.data && result.data.readOneMember) {
                    const member = result.data.readOneMember;
                    that.setState({
                        email: member.Email,
                        firsName: member.FirstName,
                        surname: member.Surname,
                        phoneNumber: member.PhoneNumber,
                        loading: false,
                    });
                }
            });

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const { password } = this.state;
            return value === password || !password
        });
    }

    render() {
        const { loading } = this.state;

        if (loading) return <Spinner />;
        return (
            <Mutation
                client={getClient()}
                mutation={updateMemberMutation}
                onCompleted={() => this.setState({ beingSaved: false })}
            >
                {this.renderForm}
            </Mutation>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { data, loading } = results;

        const { email, firstName, surname, password, phoneNumber, beingSaved } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();

            this.setState({beingSaved: true});
            if (loading) return;

            const input = {
                ID: getUser().ID,
                Email: email,
                FirstName: firstName,
                Surname: surname,
                PhoneNumber: phoneNumber,
            };
            if (password) {
                input.Password = password;
            }

            mutate({variables: {
                input: input
            }});
        };

        return (
            <Paper elevation={0} className='step-forms-container'>
                <ValidatorForm
                    ref="form"
                    onSubmit={onSubmit}
                    onError={errors => console.log(errors)}
                >
                    <Grid container spacing={0} className='form-panels-container'>
                        <Grid item className="form-panels">
                            <Accordion
                                title="Basic Details"
                                titleI18nKey="myAccount.tabBasic"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderBasic()}
                            </Accordion>

                            <Accordion
                                title="Change Password"
                                titleI18nKey="myAccount.tabPasswords"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderPasswords()}
                            </Accordion>

                            <Grid item>
                                <div className='action-buttons' style={{ marginTop: 20 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="button-base primary"
                                        disabled={beingSaved}
                                    >
                                        {beingSaved && <Spinner/>}
                                        Update
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Paper>
        );
    };

    renderBasic() {
        const { firsName, surname, email, phoneNumber } = this.state;
        return (
            <Fragment>
                <Grid container spacing={0}>
                    <Grid item className="field-holder">
                        <TextValidator
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.firstName">First Name</Trans>}
                            value={firsName}
                            onChange={this.onChangeText('firsName')}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className="field-holder">
                        <TextValidator
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.surname">Surname</Trans>}
                            value={surname}
                            onChange={this.onChangeText('surname')}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className="field-holder">
                        <TextValidator
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.email">Email</Trans>}
                            value={email}
                            onChange={this.onChangeText('email')}
                            validators={['required', 'isEmail']}
                            errorMessages={['this field is required', 'email is not valid']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className="field-holder">
                        <TextValidator
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.phoneNumber">Phone Number</Trans>}
                            value={phoneNumber}
                            onChange={this.onChangeText('phoneNumber')}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderPasswords() {
        const { password, confirmPassword } = this.state;
        return (
            <Fragment>
                <Grid container spacing={0}>
                    <Grid item className="field-holder">
                        <TextField
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.password">Password</Trans>}
                            type="password"
                            fullWidth
                            value={password}
                            onChange={this.onChangeText('password')}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item className="field-holder">
                        <TextValidator
                            variant="outlined"
                            label={<Trans i18nKey="myAccount.confirmPassword">Confirm Password</Trans>}
                            type="password"
                            fullWidth
                            onChange={this.onChangeText('confirmPassword')}
                            value={confirmPassword}
                            validators={['isPasswordMatch']}
                            errorMessages={['password mismatch']}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    onChangeText = name => e => {
        this.setState({ [name]: e.target.value });
    };

}

const readMemberQuery = gql`
    query ReadOneMember($ID: ID!) {
        readOneMember(ID: $ID) {
            ID
            Email
            FirstName
            Surname
            PhoneNumber
        }
    }
`;

const updateMemberMutation = gql`
    mutation UpdateMember($input: MemberUpdateInputType!) {
        updateMember(Input: $input) {
            ID
            FirstName
            Surname
            Email
            PhoneNumber
        }
    }
`;

export default Account;
