import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button';
import { Editor } from '@tinymce/tinymce-react';
import Listing from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import CategoryTreeField from "../../../components/form/CategoryTreeField";
import Spinner from "../../../components/loading/Spinner";
import {tinyMceAPIKey} from "../../../environment";
import {getClient} from "../../../utils/apollo";

class ListingTab extends Component {

    state = {
        loadingReviewButton: false,
    };

    render() {
        return null;
    }

    renderButtons() {
        const { saveButton } = this.props;

        return (
            <Fragment>
                <div className="form-footer">
                    {this.showSubmitForReview() && (
                        <Button
                            variant="contained"
                            onClick={e => this.onClickSubmitForReview(e)}
                            disabled={this.state.loadingReviewButton}
                        >
                            {this.state.loadingReviewButton && <Spinner size="xs"/>}
                            Submit for Review
                        </Button>
                    )}

                    {this.showSaveButton() && saveButton}
                </div>
            </Fragment>
        );
    }

    showSaveButton() {
        return !this.state.loadingReviewButton;
    }

    showSubmitForReview() {
        const { form, config } = this.props;
        const canShowReview = this.canShowSubmitForReview();
        if (!config.ListingPreviewURLPattern) {
            return canShowReview;
        }
        return canShowReview && !!form.getField('VersionForReview');
    }

    canShowSubmitForReview() {
        const { form } = this.props;
        return (
            this.state.loadingReviewButton
            || (form.getField('Version') > form.getField('VersionForReview'))
            || (form.getField('Status') !== 'Draft' && form.isDirty)
        );
    }

    onClickSubmitForReview() {
        const { form } = this.props;

        form.setExtraField('Status', 'Pending');

        this.submitForReview();
    }

    submitForReview() {
        const { form } = this.props;
        const that = this;

        this.setState({ loadingReviewButton: true });

        getClient()
            .mutate(form.getMutation())
            .then((response) => {
                form.onSaveCompleted(response);
                that.setState({ loadingReviewButton: false });
            })
            .catch(form.handleMutateError);
    }
}

export default ListingTab;