import { createMuiTheme, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';

const font = "'Poppins', sans-serif";


let theme = createMuiTheme({
    palette: {
        background: {
            paper: '#FFFFFF',
            default: 'transparent'
        },
        text: {
        }
    },
    typography: {
        fontFamily: font,
        fontSize: 14,
        lineHeight: 1.5,
        htmlFontSize: 20,
        h1: {
            fontFamily: font,
            fontSize: 32,
            lineHeight: 1.2,
            fontWeight: 300
        },
        h2: {
            fontFamily: font,
            fontSize: 32,
            lineHeight: 1.2,
            fontWeight: 300
        },
        h3: {
            fontFamily: font,
            fontSize: 22,
            lineHeight: 1.3,
            fontWeight: 300
        },
        h4: {
            fontFamily: font,
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 500
        },
        h5: {
            fontFamily: font,
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 500
        },
        h6: {
            fontFamily: font,
            fontSize: 14,
            lineHeight: 1.25,
            fontWeight: 600
        },
        body: {
            fontFamily: font
        },
        body1: {
            fontFamily: font,
            fontSize: 14,
            htmlFontSize: 16,
            lineHeight: 1.24
        },
        body2: {
            fontFamily: font,
            fontSize: 14,
            htmlFontSize: 16,
            lineHeight: 1.24
        }
    }
});


theme = responsiveFontSizes(theme);
export default theme;


