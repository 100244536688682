import React from 'react';
import { Redirect, Route as StandardRoute } from 'react-router-dom';
import { isSignedIn } from '../utils/sessions';

const Route = ({ auth, anon, redirectPath, ...rest }) => {
    if (auth) return <GatedRoute condition={!isSignedIn()} redirectPath={redirectPath || '/login'} {...rest} />;
    if (anon) return <GatedRoute condition={isSignedIn()} redirectPath={redirectPath || '/'} {...rest} />;
    return <StandardRoute {...rest} />;
};

const GatedRoute = ({ condition, redirectPath, ...rest }) => {
    if (!condition) return <StandardRoute {...rest} />;

    const { component, ...other } = rest;
    return (
        <StandardRoute
            {...other}
            render={props => (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state: { referrer: props.location }
                    }}
                />
            )}
        />
    );
};

export default Route;
