import React from 'react';
import gql from 'graphql-tag';
import Tree, { TreeNode } from 'rc-tree';
import Category from "../../fragments/Category";
import { Query } from 'react-apollo';
import Spinner from '../../components/loading/Spinner';
import 'rc-tree/assets/index.css'
import {getClient} from "../../utils/apollo";

class CategoryTreeField extends React.Component {
    constructor(props) {
        super(props);

        this.onCheck = this.onCheck.bind(this);
        this.state = {
            checkedKeys: [],
            isLoading: true,
            checkStrictlyKeys: {
                checked: [],
                halfChecked: []
            },
            categories: [],
        };
    }

    componentDidMount() {
        const { checkedKeys } = this.props;
        const that = this;

        getCategoriesTree().then(categories => {
            that.setState({
                categories: categories,
                checkedKeys: checkedKeys ? checkedKeys : [],
                isLoading: false
            });
        });
    }

    render() {
        const { leafOnly, threshold, readOnly, isEvent } = this.props;
        const { checkedKeys, categories, isLoading } = this.state;

        if (isLoading) return <Spinner />;

        let expandedKeys = [];
        const loop = (items) => items.filter(e => !!isEvent ? e.ClassType === 'Event' : e.ClassType !== 'Event').map((item) => {
            const disable = checkedKeys.includes(String(item.ID))
                ? false
                : (leafOnly && !!item.Children.length) || threshold <= checkedKeys.length;

            if (item.Children && item.Children.length) {
                return (
                    <TreeNode
                        title={item.Title}
                        key={item.ID}
                        disableCheckbox={readOnly || disable}
                    >
                        {loop(item.Children)}
                    </TreeNode>
                );
            }
            if (Array.isArray(checkedKeys)) {
                //checkedKeys.includes(String(item.ID)) &&
                if (!expandedKeys.includes(item.Parent.ID)) {
                    expandedKeys.push(item.Parent.ID);
                }
            }

            return (
                <TreeNode
                    title={item.Title}
                    key={item.ID}
                    isLeaf={!item.Children || !item.Children.length}
                    expanded="true"
                    disableCheckbox={readOnly || disable}
                />
            );
        });
        const treeNodes = loop(categories);

        return (
            <div>
                <Tree
                    checkable
                    onCheck={this.onCheck}
                    checkedKeys={checkedKeys}
                    defaultExpandedKeys={expandedKeys}
                    checkStrictly
                >
                    {treeNodes}
                </Tree>
            </div>
        );
    }

    onCheck(checkedKeys) {
        const { onSelected } = this.props;
        this.setState({
            checkedKeys: checkedKeys.checked,
        });
        if (onSelected) {
            onSelected(checkedKeys.checked);
        }
    }
}

export const getCategoriesTree = async () => {
    const asyncQuery = await getClient().query({ query: categoriesQuery, variables: { parentID: 0 } });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readCategories : {};
};

const categoriesQuery = gql`
    ${Category}
    query ReadCategories($parentID: Int) {
        readCategories(parentID: $parentID) {
            ...Category
        }
    }
`;

export default CategoryTreeField;
