import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { Listing } from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import ListingTab from "./ListingTab";
import ListingPreview from "../ListingPreview";
import Spinner from "../../../components/loading/Spinner";

class Preview extends ListingTab {

    render() {
        const { form, config } = this.props;

        return (
            <Fragment>
                <Grid item className="preview-buttons">
                    {this.showSubmitForReview() && (
                        <Button
                            variant="contained"
                            onClick={e => this.onClickSubmitForReview(e)}
                            disabled={this.state.loadingReviewButton}
                        >
                            {this.state.loadingReviewButton && <Spinner size="xs"/>}
                            Submit for Review
                        </Button>
                    )}
                </Grid>

                <Grid container spacing={0} className='form-panels-container'>
                    <ListingPreview form={form} config={config} />
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    showSaveButton() {
        return false;
    }

    showSubmitForReview() {
        return this.canShowSubmitForReview();
    }
}

export default (isEvent = false) => ({
    id: 'preview',
    tabURL: `/${isEvent ? 'event' : 'listing'}/edit/:id/preview`,
    label: 'Preview',
    component: Preview,
    fragment: Listing(),
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

    }
});