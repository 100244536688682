import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import { Icon, Button, Typography } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next'
import Grid from '../../components/layout/Grid';
import DataTable from '../../components/dataTable/DataTable'
import {isFunction, joinDefined} from "../../utils/strings";
import {getUser} from "../../utils/sessions";
import ViewHeader from '../../components/header/ViewHeader';
import Spinner from '../../components/loading/Spinner';
import Table, { Row, Cell } from '../../components/table/Table';
import CreateListingModal from './Modals/CreateListingModal';
import ClaimListingModal from "./Modals/ClaimListingModal";

class Listings extends Component {

    state = {
        showCreateModal: false,
        showClaimingModal: false,
        hasClaimedListings: false,
    };

    refetchClaims = null;

    config = null;

    static getDerivedStateFromProps({ match }, oldState) {
        if (match.params.type && match.params.type === 'new') {
            return {
                showCreateModal: true,
            }
        }
        if (match.params.type && match.params.type === 'claim') {
            return {
                showClaimingModal: true
            }
        }
        return null;
    }

    render() {
        const { showClaimingModal, showCreateModal } = this.state;
        const { history, config } = this.props;

        return (
            <Fragment>
                <div className={'section'}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <h1>
                                <Trans i18nKey="myListings.listView.title">
                                    List your business on
                                </Trans>
                                {!!config ? config.SiteTitle : 'our site'}
                            </h1>
                            <p>
                                <Trans i18nKey="myListings.listView.descPart1">
                                    Get your businesses listed on
                                </Trans>
                                {!!config ? config.SiteTitle : 'our site'}
                                <Trans i18nKey="myListings.listView.descPart2">
                                    to be found online and generate more bookings. Learn more about
                                </Trans>
                                {!!config && (
                                    <a href={config.SiteLink} target="_blank">{config.SiteTitle}</a>
                                )}
                            </p>
                        </Grid>
                    </Grid>
                </div>

                {this.renderListings()}

                {this.renderClaimedRequests()}

                <CreateListingModal
                    onClose={() => this.onCloseCreateModal()}
                    open={showCreateModal}
                    history={history}
                />

                <ClaimListingModal
                    onClose={() => this.onCloseClaimingModal()}
                    open={showClaimingModal}
                    onClaimed={e => this.fetchClaimedRequests()}
                />

            </Fragment>
        );
    }

    renderListings() {
        const { history } = this.props;
        return (
            <div className={'section'}>
                <Grid container spacing={3}>
                    <Grid item>
                        <ViewHeader
                            variant="record"
                            titleTag="h2"
                            title={<Trans i18nKey="myListings.listView.listingsTitle">Your Current Listings</Trans>}
                        >
                            <Button variant="outlined" onClick={e => history.push('/listings/new')}>
                                <Trans i18nKey="myListings.listView.addTitle">
                                    Add a new listing
                                </Trans>
                            </Button>
                        </ViewHeader>

                        <Grid contianer className='table-container'>
                            <Grid item className="listings">
                                <DataTable
                                    className="table-listings"
                                    queryName="readListings"
                                    fragment={listingFragment}
                                    columns={listingColumns}
                                    variables={{ limit: 10, memberID: getUser().ID }}
                                    brandProperty="ID"
                                    searchVariable="contains"
                                    tableTitle="Listings"
                                    versioningMode="DRAFT"
                                    showHeader={true}
                                    noResultsMessage={<Trans i18nKey="myListings.noResultsMessage">You have no listings added yet.</Trans>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderClaimedRequests() {
        const { config } = this.props;
        return (
            <div className={'section'}>
                <Grid container spacing={3}>
                <Grid item>

                    <ViewHeader
                        variant="record"
                        titleTag="h2"
                        title={<Trans i18nKey="myListings.claimListing.addTitle">Claim an existing listing</Trans>}
                    >

                        <Button
                            variant="outlined"
                            onClick={e => this.setState( { showClaimingModal: true })}
                            style={{ marginRight: 10 }}
                        >
                            <Trans i18nKey="myListings.claimListing.claim">
                                Claim a listing
                            </Trans>
                        </Button>

                    </ViewHeader>



                    <Typography variant="body1">
                        <Trans i18nKey="myListings.claimListing.addContent">
                            If your business is already listed on
                            {!!config ? (
                                <strong> <a href={config.SiteLink} target="_blank">{config.SiteTitle}</a>, </strong>
                            ) : 'our site'}
                            you can claim the listing by clicking the button below.
                        </Trans>
                    </Typography>
                </Grid>

                <Query
                    query={claimedRequestsQuery}
                    variables={{ memberID: getUser().ID}}
                    fetchPolicy="cache-and-network"
                >
                    {results => {
                        const { loading, data, refetch } = results;

                        if (loading) return <Spinner />;

                        this.refetchClaims = refetch;

                        const requests = data.readClaimListingRequests;

                        return (
                            <Grid item>
                                {!!requests.length && (
                                    <Fragment>
                                        <ViewHeader
                                            variant="record"
                                            titleTag="h3"
                                            title={<Trans i18nKey="myListings.claimListing.title">Your Claimed Listings</Trans>}
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={e => this.setState( { showClaimingModal: true })}
                                                style={{ marginRight: 10 }}
                                            >
                                                <Trans i18nKey="myListings.claimListing.claimAnother">
                                                    Claim another listing
                                                </Trans>
                                            </Button>
                                        </ViewHeader>
                                        <Grid contianer className='table-container'>
                                            <Grid item className="listings">
                                                <Grid item className='table-holder'>
                                                    <Table
                                                        columns={[{ label: 'Title' }, { label: 'Category' }, { label: 'Status' }]}
                                                    >
                                                        {requests.map((claim, rowIndex) => {
                                                            return (
                                                                <Row pad key={'claim-' + claim.ID}>
                                                                    <Cell dataLabel="Title">{claim.Listing.Title}</Cell>
                                                                    <Cell dataLabel="Category">{claim.Listing.MainCategory.Title}</Cell>
                                                                    <Cell dataLabel="Status">{claim.Status}</Cell>
                                                                </Row>
                                                            );
                                                        })}
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        );

                    }}
                </Query>
            </Grid>
            </div>
        );
    }

    fetchClaimedRequests() {
        if (this.refetchClaims && isFunction(this.refetchClaims)) {
            console.log('----refetching')
            this.refetchClaims();
        }
    }

    onCloseCreateModal() {
        const { history } = this.props;
        this.setState({ showCreateModal: false });
        history.push('/listings');
    }

    onCloseClaimingModal() {
        const { history } = this.props;
        this.setState({ showClaimingModal: false });
        history.push('/listings');
    }
}

export const listingColumns = [
    {
        label: 'Logo',
        propertyPath: 'Logo',
        renderCell: (row, value) => {
            const imageSrc = !!row.Logo.ImageThumbnailURL
                ? row.Logo.ImageThumbnailURL
                : row.MainImage.ImageThumbnailURL;
            return imageSrc ? <img src={imageSrc} /> : '';
        }
    },
    {
        label: 'Name, Category & Address',
        renderCell: row => {
            return (
                <Fragment>
                    <Typography variant="h4">
                        {row.Title}
                    </Typography>
                    <Typography variant="body1">
                        {row.MainCategory.Title}
                    </Typography>
                    <Typography variant="body1">
                        {joinDefined([row.City, row.State], ' - ')}
                    </Typography>
                </Fragment>
            );
        }
    },
    {
        label: 'Contact Details',
        hiddenSm: true,
        renderCell: row => {
            return (
                <Fragment>
                    <ul className='table-list table-list--icons'>
                        <li>
                            <Icon>room</Icon> {
                                row.StreetAddress ? joinDefined([
                                    row.StreetAddress,
                                    row.City,
                                    row.Suburb,
                                    row.State,
                                    row.PostCode,
                                ], ', ') : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>call</Icon> {
                                row.Phone ? <a href={'tel:'+row.Phone} target="_blank">{row.Phone}</a> : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>mail</Icon> {
                                row.Email ? <a href={'mailto:'+row.Email} target="_blank">{row.Email}</a> : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>http</Icon> {
                                row.Website
                                    ? <a href={row.Website} target="_blank">{row.Website.replace('http://', '').replace('https://', '')}</a>
                                    : ' N/A '
                            }
                        </li>
                    </ul>
                </Fragment>
            );
        }
    },
    {
        label: 'Status',
        hiddenSm: true,
        renderCell: row => {
            return (
                <span>{row.Status}</span>
            );
        }
    },
    {
        label: '',
        key: 'action',
        renderCell: (row, value) => {
            return (
                <Fragment>
                    <Button href={`/${row.ClassNameShort === 'Event' ? 'event' : 'listing'}/edit/${row.ID}`} variant="outlined">
                        Manage {row.ClassNameShort === 'Event' ? 'Event' : 'Listing'}
                    </Button>
                </Fragment>
            );
        }
    }
];

export const listingSummaryFields = `
    ID
    ClassNameShort
    Status
    Title
    StreetAddress
    City
    Suburb
    State
    PostCode
    Phone
    Email
    Website
    MainCategory {
        ID
        Title
    }
    Logo {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
    MainImage {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
`;

export const listingFragment = gql`
    fragment ListingFragment on Listing {
        ${listingSummaryFields}
    }
`;

const claimListingFields = `
    ID
    Title
    MainCategory {
        ID
        Title
    }
    Logo {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
    MainImage {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
`;
const claimedRequestsQuery = gql`
    query ReadClaimListingRequests($memberID: ID) {
        readClaimListingRequests(memberID: $memberID) {
            ID
            Status
            Listing {
                ... on Listing {
                    ${claimListingFields}
                }
                ... on Accommodation {
                    ${claimListingFields}
                }
                ... on Activity {
                    ${claimListingFields}
                }
                ... on Event {
                    ${claimListingFields}
                }
            }
        }
    }
`;

export const hasListingsClaimsQuery = gql`
    query ReadMemberHasListings($memberID: ID!) {
        readMemberHasListings(MemberID: $memberID) {
            HasListings
            HasClaims
        }
    }
`;

export default withTranslation()(Listings);
