import gql from "graphql-tag";
import {ListingFields, TripAdvisorFields, QualmarkFields, BookitFields} from "./Listing";

export const Event = (includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields) => {
    return gql`
		fragment Event on Event {
			${ListingFields(includeTripAdvisor, includeQualmark, includeBookit, includeCustomFields)}
			
            Performances {
                ID
                StartDate
                EndDate
                Prices
            }
		}
	`;
}
