import React, { Component, createContext } from 'react';

const { Provider, Consumer } = createContext({ message: '' });

let globalSnackbarMessage;

// Call this to show a snackbar from anywhere in the code
export function setSnackbarMessage(message) {
	globalSnackbarMessage(message);
}

export class SnackbarMessageProvider extends Component {
	constructor(props) {
		super(props);

		globalSnackbarMessage = this.setSnackbarMessage;

		this.state = {
			snackbarMessage: '',
			setSnackbarMessage: this.setSnackbarMessage
		};
	}

	render() {
		const { children } = this.props;
		return <Provider value={{ ...this.state }}>{children}</Provider>;
	}

	setSnackbarMessage = snackbarMessage => {
		this.setState({ snackbarMessage });
	};
}

export const SnackbarMessageConsumer = Consumer;

export function withSnackbarMessage(C) {
	return props => <Consumer>{value => <C {...value} {...props} />}</Consumer>;
}
